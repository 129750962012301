.rangeNumbers {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.currentNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--secondary-font-family);
  font-weight: 600;
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  color: var(--color-blue-dark);
  background: var(--color-gray);
  border-radius: 32px;
  padding: 0 15px;
  cursor: default;
  min-width: 120px;
  height: 46px;
  margin: 0 10px 0 0;
}

.currentNumber {
  white-space: nowrap;
}

@media screen and (max-width: 1044px) {
  .currentNumber {
    min-width: 80px;
    height: 37px;
    padding: 0 12px;
    margin: 0 7px 0 0;
  }
}

.next,
.prev {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-blue-dark);
  transition: var(--transition);
}

@media screen and (max-width: 1044px) {
  .next,
  .prev {
    width: 24px;
    height: 24px;
  }
}

.next:hover,
.prev:hover {
  color: var(--color-blue);
}

.notActive .next,
.notActive .prev,
.notActive .next:hover,
.notActive .prev:hover,
.rangeNumbers .disabled,
.rangeNumbers .disabled:hover {
  color: var(--color-gray-disabled);
  cursor: default;
}

.notActive .currentNumber {
  color: var(--color-gray-disabled);
}
