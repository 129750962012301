.reactSelectContainer {
  width: 100%;
}

.reactSelectContainer .reactSelect__control {
  font-family: var(--font-family);
  font-size: var(--default-font-size-v2);
  line-height: var(--default-line-height-v2);
  color: var(--color-blue-dark);
  background: radial-gradient(
    97.01% 103.71% at 0% 3.4%,
    rgba(255, 255, 255, 0.56) 0%,
    rgba(255, 255, 255, 0.08) 100%,
    rgba(255, 255, 255, 0.16) 100%
  );
  border: 1px solid #e9e9ea;
  box-shadow: 0px 4px 10px rgba(39, 45, 84, 0.1);
  backdrop-filter: blur(12px);
  border-radius: 40px;
  padding: 17px 25px;
  width: 100%;
  height: 73px;
  outline: none;
  transition: var(--transition);
}

.reactSelectContainer .reactSelect__control:hover {
  border-color: #e9e9ea;
}

.reactSelectContainer .reactSelect__control--is-focused,
.reactSelectContainer .reactSelect__control--is-focused:hover {
  background: var(--color-white);
  border-color: var(--color-blue-dark);
  color: var(--color-blue-dark);
}

.reactSelectContainer.withError .reactSelect__control {
  border-color: red;
}

.reactSelectContainer .reactSelect__value-container {
  padding: 0 10px 0 0;
}

.reactSelectContainer .reactSelect__input-container,
.reactSelectContainer .reactSelect__single-value {
  font-family: var(--font-family);
  font-size: var(--default-font-size-v2);
  line-height: var(--default-line-height-v2);
  color: var(--color-blue-dark);
  margin: 0;
  padding: 0;
}

.reactSelectContainer .reactSelect__placeholder {
  color: #e9e9ea;
}

.reactSelectContainer
  .reactSelect__indicator-separator.css-1okebmr-indicatorSeparator {
  width: 0;
}

.reactSelectContainer .reactSelect__indicator.reactSelect__dropdown-indicator {
  padding: 0;
  color: var(--color-yellow);
  transition: var(--transition);
}

.reactSelectContainer
  .reactSelect__control--is-focused
  .reactSelect__indicator.reactSelect__dropdown-indicator {
  color: var(--color-blue-dark);
  transform: rotate(-180deg);
}

.reactSelectContainer .reactSelect__dropdown-indicator svg {
  width: 38px;
  height: 38px;
  transform: rotate(90deg);
}

@media screen and (max-width: 1044px) {
  .reactSelectContainer .reactSelect__control {
    height: 48px;
    padding: 0 16px;
    border-radius: 24px;
  }

  .reactSelectContainer .reactSelect__dropdown-indicator svg {
    width: 24px;
    height: 24px;
  }
}
