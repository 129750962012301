.step {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.stepContent {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 25px;
}

@media (max-width: 768px) {
  .stepContent {
    padding: 0 15px;
  }
}

.h3 {
  margin: 16px 0;
}

.mainP {
  margin: 0 0 16px 0;
}

.mainP a {
  text-decoration: underline;
}

.btnsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

@media (max-width: 768px) {
  .btnsContainer {
    margin-top: 5px;
    flex-direction: column;
  }
}

.settingsBtn {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--color-blue-dark);
  text-decoration: underline;
  margin-right: 25px;
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
}

@media (max-width: 768px) {
  .settingsBtn {
    margin: 0 0 15px 0;
  }
}

.checkContainer {
  margin-top: 25px;
  padding: 0 20px 0 0;
}

.checkh4 {
  margin: 0;
}
