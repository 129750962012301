.slide {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 256px;
  height: auto;
  position: relative;
  cursor: pointer;
}

@media (max-width: 1044px) {
  .slide {
    width: 162px;
  }
}

.activeSlide {
  cursor: default;
}

.packageContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 224px;
  margin: 0 16px;
  padding-bottom: 24px;
  border-radius: 16px;
  border-color: transparent;
}

@media (max-width: 1044px) {
  .packageContainer {
    width: 146px;
    margin: 0 8px;
    padding-bottom: 30px;
  }
}

.activeSlide .packageContainer {
  background: #f0f0d1;
  border: 1px solid var(--color-yellow);
  border-bottom: none;
  box-shadow: 0px 4px 10px rgba(39, 45, 84, 0.1);
  border-radius: 16px 16px 0 0;
  transition: all 300ms ease;
}

.packageBox {
  justify-content: space-between;
  width: 224px;
  height: 280px;
  padding: 24px 18px 12px;
  overflow: hidden;
}

@media (max-width: 1044px) {
  .packageBox {
    width: 146px;
    height: 182px;
    padding: 16px 12px 8px;
  }
}

.slide:not(.activeSlide):hover .packageBox {
  border: 1px solid var(--color-yellow);
  box-shadow: 0px 4px 10px rgba(39, 45, 84, 0.1);
  transition: all 300ms ease;
}

.activeSlide .packageBox {
  background: none;
  border: none;
  box-shadow: none;
  backdrop-filter: none;
  border-radius: 16px 16px 0 0;
}

.text {
  font-size: 25px;
  line-height: 34px;
  color: var(--color-blue-dark);
  text-align: center;
}

@media (max-width: 1044px) {
  .text {
    font-size: 16px;
    line-height: 21.79px;
  }
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.imgContainer {
  width: 100%;
  height: 84px;
  text-align: center;
  font-size: 12px;
}

.img {
  height: auto;
  width: auto;
  max-width: 100px;
  max-height: 100%;
}

@media (max-width: 1044px) {
  .imgContainer {
    height: 43px;
  }

  .img {
    max-width: 65px;
  }
}

.line {
  width: 100%;
  height: 6px;
  background: var(--color-yellow);
  border-radius: 2px;
  margin: 20px 0 4px;
  transition: all 300ms ease;
}

@media (max-width: 1044px) {
  .line {
    height: 2px;
    margin: 16px 0 4px;
  }
}

.activeSlide .line {
  background: var(--color-blue-dark);
}

.arrow {
  display: flex;
  justify-content: center;
  width: 100%;
}

.arrowIcon {
  fill: var(--color-yellow);
  transform: rotate(90deg);
  width: 38px;
  height: 38px;
}

@media (max-width: 1044px) {
  .arrowIcon {
    width: 24px;
    height: 24px;
  }
}

.activeSlide .arrowIcon {
  fill: var(--color-blue-dark);
  transform: rotate(-90deg);
}
