input.formControl:-webkit-autofill,
input.formControl:-webkit-autofill:hover,
input.formControl:-internal-autofill-selected {
  -webkit-text-fill-color: var(--color-blue-dark);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
  box-shadow: 0 0 0px 1000px var(--color-white) inset;
  border: 1px solid #e9e9ea;
}

input.formControl:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-blue-dark);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
  box-shadow: 0 0 0px 1000px var(--color-white) inset;
  background: var(--color-white);
  border: 1px solid var(--color-blue-dark);
}

.formControl {
  font-family: var(--font-family);
  font-size: var(--default-font-size-v2);
  line-height: var(--default-line-height-v2);
  color: var(--color-blue-dark);
  background: radial-gradient(
    97.01% 103.71% at 0% 3.4%,
    rgba(255, 255, 255, 0.56) 0%,
    rgba(255, 255, 255, 0.08) 100%,
    rgba(255, 255, 255, 0.16) 100%
  );
  border: 1px solid #e9e9ea;
  box-shadow: 0px 4px 10px rgba(39, 45, 84, 0.1);
  backdrop-filter: blur(12px);
  border-radius: 40px;
  padding: 17px 25px;
  width: 100%;
  height: 73px;
  outline: none;
  transition: var(--transition);
  text-align: center;
}

.formControl:focus {
  background: var(--color-white);
  border: 1px solid var(--color-blue-dark);
  color: var(--color-blue-dark);
}

.formControl::placeholder {
  color: #e9e9ea;
}

.formControl:focus::placeholder {
  color: var(--color-gray-dark);
}

.formControl::placeholder-shown {
  border: 1px solid rgba(255, 255, 255, 0.3);
}

@media screen and (max-width: 1044px) {
  .formControl {
    height: 48px;
    padding: 15px 16px;
    border-radius: 24px;
  }
}

.confimationModalContent {
  max-width: 560px;
  min-height: 440px;
}

@media (max-width: 1044px) {
  .confimationModalContent {
    max-width: 400px;
    min-height: 340px;
  }
}

.confimationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 440px;
}

@media (max-width: 1044px) {
  .confimationContainer {
    min-height: 340px;
  }
}

.confimationContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confimationImg {
  padding: 0 0 15px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confimationImg img {
  width: 100px;
  height: 100px;
}

.confirmationInput {
  width: 286px;
}

@media (max-width: 1044px) {
  .confirmationInput {
    width: 208px;
  }
}

.resendCountdown {
  margin: 0;
  font-size: 14px;
}

.resendAlternativeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 15px;
}

.resendAlternativeP {
  font-size: 12px;
  margin: 0 0 5px 0;
}

.confirmationBtn {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1044px) {
  .confirmationBtn {
    margin: 16px 0;
  }
}

.resendContainer {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.resendContainer .formControl {
  width: 145px;
  height: 26px;
  padding: 0 5px;
  font-size: 12px;
}
