.loginBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 76px 0 40px 0;
}

div.contentBox {
  background: rgba(255, 255, 255, 0.6);
  padding: 0;
}

.loginContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 40px;
  width: 100%;
  background: url("../../images/schutz-icon.svg") no-repeat;
  background-position: calc(100% - 100px) 120px;
}

.formContainer {
  width: 560px;
}

.passwordHintContainer {
  position: relative;
}

.passwordHint {
  display: flex;
  align-items: center;
  /* color: var(--color-gray-dark); */
}

.infoIcon {
  color: var(--color-blue-dark);
  width: 38px;
  height: 38px;
  cursor: pointer;
  margin-left: 10px;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 44px;
}

@media screen and (max-width: 1044px) {
  .loginBlock {
    padding: 24px 0 50px 0;
  }

  .loginContent {
    background: none;
    padding: 32px 16px 24px;
  }

  .formContainer {
    width: 100%;
  }

  .passwordHint {
    padding-top: 8px;
  }

  .infoIcon {
    width: 24px;
    height: 24px;
  }

  .btnContainer {
    margin-top: 24px;
  }
}
