.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: var(--color-yellow);
}

.icon {
  width: 38px;
  height: 38px;
  color: var(--color-blue-dark);
}

@media (max-width: 1044px) {
  .circle {
    width: 46px;
    height: 46px;
  }

  .icon {
    width: 24px;
    height: 24px;
  }
}
