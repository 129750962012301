.blockContainer {
  padding: 70px 0;
}

.block {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 38px 73px;
  position: relative;
}

@media screen and (max-width: 1044px) {
  .blockContainer {
    padding: 24px 0 0;
  }

  .blockTwoContainer {
    padding: 24px 0;
  }

  .block {
    padding: 24px 16px 32px;
  }
}

.title {
  margin-bottom: 18px;
}

.closeBtn {
  position: absolute;
  top: 36px;
  right: 36px;
}

@media screen and (max-width: 1044px) {
  .closeBtn {
    top: 16px;
    right: 16px;
  }
}

.blockP {
  margin: 0;
}

.checkoutBlock {
  padding: 30px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
}

.blockLeft {
  flex-grow: 1;
}

.configCotnainer {
  padding: 30px 0 0 0;
}

.formContainer {
  width: 50%;
}

@media (max-width: 1044px) {
  .formContainer {
    width: 100%;
  }
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loaderPadding {
  padding-bottom: 30px;
}

.ibanPrefiledContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px 0 35px;
}
