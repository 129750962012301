.h1 {
  font-family: var(--font-family);
  font-weight: 600;
  margin: 0 0 30px;
  color: var(--default-text-color);
}

.h2 {
  font-family: var(--font-family);
  font-weight: 600;
  margin: 25px 0 35px;
}

.h4 {
  font-family: var(--font-family);
  font-weight: 600;
  margin: 15px 0;
  font-size: 16px;
}

.textRight {
  text-align: right;
}

.block {
  width: 100%;
  margin-bottom: 20px;
}
