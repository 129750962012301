.toggleSwitch,
.disabled,
.disabled:hover {
  width: 76px;
  height: 38px;
  margin: 0;
  padding: 2px;
  background: var(--color-gray);
  border: 1px solid var(--color-gray);
  border-radius: 24px;
  cursor: pointer;
}

@media screen and (max-width: 1044px) {
  .toggleSwitch,
  .disabled,
  .disabled:hover {
    width: 48px;
    height: 24px;
    padding: 1px;
  }
}

.toggleSwitch:hover {
  border-color: #f4f4b5;
}

.active {
  background: var(--color-yellow);
  border-color: var(--color-yellow);
}

.knob {
  width: 32px;
  height: 32px;
  background: var(--color-blue-dark);
  border-radius: 50%;
  left: 0;
  position: relative;
  transition: left 0.3s ease-out;
}

@media screen and (max-width: 1044px) {
  .knob {
    width: 20px;
    height: 20px;
  }
}

.active .knob {
  left: 38px;
}

@media screen and (max-width: 1044px) {
  .active .knob {
    left: 24px;
  }
}

.disabled,
.disabled:hover {
  cursor: default;
  background: var(--color-gray);
  border-color: var(--color-gray);
}

.disabled .knob {
  background: var(--color-gray-disabled);
}
