.modalContent {
  width: 100%;
  max-width: var(--container-lg-width-fixed);
  min-height: 400px;
  max-height: 100%;
  padding: 16px;
}

@media (max-width: 1070px) {
  .modalContent {
    width: 100%;
    max-width: 768px;
  }
}
