.footer {
  display: flex;
  width: 100%;
  background-color: var(--color-blue-dark);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px -4px 10px rgba(39, 45, 84, 0.1);
  border-radius: 16px 16px 0px 0px;
  backdrop-filter: blur(12px);
}

.footerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* padding: 36px 0; */
  padding: 28px 0;
}

.logosContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  max-width: 181px;
}

@media screen and (max-width: 1044px) {
  .logo img {
    max-width: 100px;
  }
}

@media screen and (max-width: 768px) {
  .footerContent {
    align-items: flex-end;
    padding: 17px 4px;
  }

  .logosContainer {
    width: 168px;
    flex-wrap: wrap;
    align-items: center;
  }

  .logo {
    width: 72px;
  }

  .logo:nth-child(1) {
    order: 1;
  }

  .logo:nth-child(2) {
    margin-top: 8px;
    order: 3;
  }

  .logo:nth-child(3) {
    margin-top: 8px;
    order: 4;
  }

  .logo:nth-child(4) {
    order: 2;
  }

  .logo img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.nav {
  display: flex;
}

.navUl {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}

.navUl li {
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  color: var(--color-white);
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: right;
}

.navUl li + li {
  margin-top: 14px;
}

.navUl li a {
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
}

.navUl li a:hover {
  color: inherit;
  text-decoration: none;
}
