.blockOneContainer {
  padding: 70px 0 0;
}

.blockTwoContainer {
  padding: 70px 0 83px;
}

.block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 38px 73px 44px;
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 83px;
}

.btn + .btn {
  margin-left: 73px;
}

.form {
  width: 100%;
}

.checkbox {
  align-items: flex-start;
}

.field {
  max-width: 555px;
}

.voucherContainer {
  margin-bottom: 44px;
}

.voucherH3 {
  margin-bottom: 25px;
}

.voucher {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.voucher .field {
  max-width: 453px;
}

.voucherInput {
  margin-bottom: 0;
}

.voucher button {
  padding: 17px 30px 18px;
  margin-left: 44px;
  min-width: 204px;
  height: 73px;
}

@media screen and (max-width: 1044px) {
  .blockContainer {
    padding: 24px 0;
  }

  .blockOneContainer {
    padding: 24px 0 0;
  }

  .blockTwoContainer {
    padding: 24px 0 48px;
  }

  .block {
    padding: 24px 16px 32px;
  }

  .blockTwoContainer .block {
    padding-bottom: 16px;
  }

  .voucherInput {
    margin-bottom: 0;
  }

  .voucher button {
    height: 48px;
    min-height: 48px;
    min-width: 140px;
    padding: 11px 20px 10px;
    margin-left: 16px;
  }

  .btnContainer {
    margin-top: 48px;
  }

  .btn {
    padding: 18px 20px 16px;
    min-width: 138px;
  }

  .btn + .btn {
    margin-left: 28px;
  }
}

@media screen and (max-width: 380px) {
  .btn + .btn {
    margin-left: 16px;
  }
}

.additionalBlock {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 38px 58px 30px;
  position: relative;
  margin-bottom: 56px;
}

.widthZIndex {
  z-index: 1;
}

.questionText {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  margin-bottom: 35px;
  font-weight: 600;
}

.questionDesc {
  font-weight: 600;
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
}

.answersContainer {
  display: flex;
  gap: 50px;
  margin-bottom: 25px;
}

.answersColumnContainer {
  flex-direction: column;
  gap: 16px;
}

.waitingLoaderContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(233, 233, 234, 0.2);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
}

.link {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: 600;
  color: var(--color-blue);
  min-height: var(--h3-line-height);
  border: 0;
  min-width: 0;
  width: auto;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: var(--transition);
}

.link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1044px) {
  .additionalBlock {
    padding: 24px 16px 16px;
  }

  .answersContainer {
    gap: 25px;
    margin-bottom: 25px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .answersContainer .defaultRadio {
    width: auto;
  }
}
