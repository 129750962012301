.blockContainer {
  padding: 70px 0;
}

.block {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 38px 73px;
  position: relative;
}

@media screen and (max-width: 1044px) {
  .blockContainer {
    padding: 24px 0;
  }

  .block {
    padding: 24px 16px 32px;
  }
}

.title {
  margin-bottom: 18px;
}

.requiredInfo {
  margin-bottom: 38px;
}

@media screen and (max-width: 1044px) {
  .title {
    margin-bottom: 16px;
  }

  .requiredInfo {
    margin-bottom: 32px;
  }
}

.closeBtn {
  position: absolute;
  top: 36px;
  right: 36px;
}

@media screen and (max-width: 1044px) {
  .closeBtn {
    top: 16px;
    right: 16px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formGroupInline {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row;
  max-width: 825px;
  width: 100%;
}

.inputAlignTop {
  align-items: flex-start;
}

.label {
  max-width: 256px;
  width: 100%;
  margin-bottom: 0;
}

.input {
  max-width: 500px;
  width: 100%;
}

@media screen and (max-width: 1044px) {
  .formGroupInline {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .label {
    max-width: none;
    margin-bottom: 16px;
  }

  .input {
    max-width: none;
    width: 100%;
  }
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 56px;
}

@media screen and (max-width: 1044px) {
  .btnContainer {
    margin-top: 32px;
  }
}

.phoneBlock {
}

.phoneBlock h3:first-child {
  margin-bottom: 10px;
}

.phoneContainer {
  display: flex;
  flex-wrap: wrap;
}

.phoneContainer span {
  padding-right: 5px;
}

.phoneA {
  display: flex;
  align-items: center;
}

.phoneIcon {
  width: 36px;
  height: 36px;
}

@media screen and (max-width: 1044px) {
  .phoneIcon {
    width: 24px;
    height: 24px;
  }
}
