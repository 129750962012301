.li {
  padding: 0 0 0 43px;
  margin: 0;
  position: relative;
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: 700;
}

.icon:before {
  background: url("../assets/images/li-icon.svg") no-repeat;
  background-size: 100%;
  content: " ";
  position: absolute;
  top: 1px;
  left: -6px;
  width: 38px;
  height: 38px;
}

@media (max-width: 1044px) {
  .li {
    padding-left: 28px;
    margin-bottom: 4px;
  }

  .icon:before {
    top: -2px;
    left: -4px;
    width: 24px;
    height: 24px;
  }
}
