.modalContent {
  min-width: 360px;
  min-height: 280px;
}

.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 35px;
}

.modalFooter button + button {
  margin-left: 25px;
}

@media (max-width: 540px) {
  .modalFooter {
    flex-direction: column;
  }

  .modalFooter button + button {
    margin-left: 0;
    margin-top: 12px;
  }

  .actionBtn {
    width: 100%;
    min-height: 48px;
    height: auto;
    padding: 11px 38px 12px;
  }
}
