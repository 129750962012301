.wrapperContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1280px) {
  .wrapperContainer {
    padding: 0 20px;
  }
}

.container {
  width: 100%;
  display: flex;
  max-width: var(--container-lg-width-fixed);
  flex-direction: column;
  justify-content: flex-start;
}
