.blockContainer {
  padding: 70px 0 0;
}

.blockTwoContainer {
  padding: 70px 0;
}

.block {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 38px 73px;
  position: relative;
}

@media screen and (max-width: 1044px) {
  .blockContainer {
    padding: 24px 0 0;
  }

  .blockTwoContainer {
    padding: 24px 0;
  }

  .block {
    padding: 24px 16px 32px;
  }
}

.title {
  margin-bottom: 18px;
}

.closeBtn {
  position: absolute;
  top: 36px;
  right: 36px;
}

@media screen and (max-width: 1044px) {
  .closeBtn {
    top: 16px;
    right: 16px;
  }
}

.h3 {
  margin-bottom: 18px;
}

.h3Sub {
  margin-bottom: 70px;
}

@media screen and (max-width: 1044px) {
  .h3Sub {
    margin-bottom: 56px;
  }
}

.h3Bold {
  font-weight: 700;
}

.marginZero {
  margin: 0;
}

.cashbacksContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -13px 0;
}

@media screen and (max-width: 1044px) {
  .cashbacksContainer {
    margin: 0;
  }
}

.shopsContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 70px;
  width: 100%;
}

@media screen and (max-width: 1044px) {
  .shopsContainer {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 0 32px;
  }
}

.cashbackListContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 14px 0 0;
  width: 100%;
}

.cashbackList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -13px 0;
}

.uppercase {
  text-transform: uppercase;
}

.paginationContainer {
  width: 100%;
  margin: 49px 0 35px;
}

@media screen and (max-width: 1044px) {
  .cashbackListContainer {
    padding: 32px 0 0;
  }

  .cashbackList {
    margin: 0;
  }

  .paginationContainer {
    margin: 24px 0 0;
  }
}
