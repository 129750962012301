.confimationContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;
}

.confimationImg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confimationImg img {
  width: 200px;
  height: 200px;
}

.confirmationInput {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 36px;
}

input.formControl:-webkit-autofill,
input.formControl:-webkit-autofill:hover,
input.formControl:-internal-autofill-selected {
  -webkit-text-fill-color: var(--color-blue-dark);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
  box-shadow: 0 0 0px 1000px var(--color-white) inset;
  border: 1px solid #e9e9ea;
}

input.formControl:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-blue-dark);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
  box-shadow: 0 0 0px 1000px var(--color-white) inset;
  background: var(--color-white);
  border: 1px solid var(--color-blue-dark);
}

.formControl {
  font-family: var(--font-family);
  font-size: var(--default-font-size-v2);
  line-height: var(--default-line-height-v2);
  color: var(--color-blue-dark);
  background: radial-gradient(
    97.01% 103.71% at 0% 3.4%,
    rgba(255, 255, 255, 0.56) 0%,
    rgba(255, 255, 255, 0.08) 100%,
    rgba(255, 255, 255, 0.16) 100%
  );
  border: 1px solid #e9e9ea;
  box-shadow: 0px 4px 10px rgba(39, 45, 84, 0.1);
  backdrop-filter: blur(12px);
  border-radius: 80px;
  padding: 17px 25px;
  width: 286px;
  height: 73px;
  outline: none;
  transition: var(--transition);
  margin-bottom: 24px;
}

.formControl:focus {
  background: var(--color-white);
  border: 1px solid var(--color-blue-dark);
  color: var(--color-blue-dark);
}

.formControl::placeholder {
  color: #e9e9ea;
}

.formControl:focus::placeholder {
  color: var(--color-gray-dark);
}

.formControl::placeholder-shown {
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.formControl:focus ~ .leftIconContainer .leftIcon,
.formControl:focus ~ .rightIconContainer .rightIcon {
  color: var(--color-blue-dark);
}

.formControlError,
.formControlError:focus {
  color: red;
  border-color: red;
}

.fieldError {
  color: red;
  font-size: 16px;
  padding-left: 40px;
}

@media screen and (max-width: 1044px) {
  .formControl {
    height: 48px;
    padding: 15px 56px;
    border-radius: 24px;
  }
}

.resendAlternativeP {
  text-align: center;
  margin: 0 0 25px 0;
}

.confirmationBtn {
  margin: 20px 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
