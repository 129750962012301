.sliderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.slidesContainer {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 232px;
  position: relative;
  padding-bottom: 38px;
}

@media screen and (max-width: 1280px) {
  .slidesContainer {
    height: 146px;
    padding-bottom: 22px;
  }
}

.slidesContainer:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -6px;
  width: 208px;
  height: 232px;
  background: url("../assets/images/active-slide.svg") no-repeat;
  background-size: contain;
  transform: translateX(-50%);
}

@media screen and (max-width: 1280px) {
  .slidesContainer:after {
    width: 134px;
    height: 146px;
  }
}

@media screen and (max-width: 1044px) {
  .slidesContainer:after {
    width: 134px;
    height: 146px;
  }
}

.slides {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100px;
}

@media screen and (max-width: 1044px) {
  .slides {
    justify-content: space-around;
    height: 56px;
  }
}

.slide {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100px;
  height: 100px;
  position: relative;
  transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media screen and (max-width: 1280px) {
  .slide {
    width: 65px;
    height: 65px;
  }
}

@media screen and (max-width: 1044px) {
  .slide {
    width: 56px;
    height: 56px;
  }
}

.hidden {
  display: none;
}

.slide:first-child,
.slide:nth-child(7) {
  bottom: -280%;
  opacity: 0.2;
  filter: blur(4px);
}

@media screen and (max-width: 1750px) {
  .slide:first-child,
  .slide:nth-child(7) {
    bottom: -250%;
  }
}

@media screen and (max-width: 1600px) {
  .slide:first-child,
  .slide:nth-child(7) {
    bottom: -230%;
  }
}

@media screen and (max-width: 1420px) {
  .slide:first-child,
  .slide:nth-child(7) {
    bottom: -210%;
  }
}

@media screen and (max-width: 1280px) {
  .slide:first-child,
  .slide:nth-child(7) {
    bottom: -200%;
  }
}

@media screen and (max-width: 1110px) {
  .slide:first-child,
  .slide:nth-child(7) {
    bottom: -190%;
  }
}

@media screen and (max-width: 1044px) {
  .slide:first-child,
  .slide:nth-child(7) {
    bottom: -210%;
  }
}

@media screen and (max-width: 890px) {
  .slide:first-child,
  .slide:nth-child(7) {
    bottom: -175%;
  }
}

.slide:nth-child(2),
.slide:nth-child(6) {
  bottom: -100%;
  opacity: 0.3;
  filter: blur(3px) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@media screen and (max-width: 1420px) {
  .slide:nth-child(2),
  .slide:nth-child(6) {
    bottom: -90%;
  }
}

@media screen and (max-width: 1280px) {
  .slide:nth-child(2),
  .slide:nth-child(6) {
    bottom: -75%;
  }
}

@media screen and (max-width: 1280px) {
  .slide:nth-child(2),
  .slide:nth-child(6) {
    bottom: -65%;
  }
}

@media screen and (max-width: 768px) {
  .slide:nth-child(2) {
    top: 0;
    opacity: 1;
    filter: none;
  }
}

.slide:nth-child(3),
.slide:nth-child(5) {
  top: 30px;
  opacity: 0.5;
  filter: blur(2px) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@media screen and (max-width: 1044px) {
  .slide:nth-child(3),
  .slide:nth-child(5) {
    top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .slide:first-child,
  .slide:nth-child(3) {
    top: 40px;
    opacity: 0.5;
    filter: blur(2px) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
}

@media screen and (max-width: 580px) {
  .slide:first-child,
  .slide:nth-child(3) {
    top: 30px;
    opacity: 0.5;
    filter: blur(2px) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
}

.slide:first-child img {
  transform: rotate(-37deg);
}

@media screen and (max-width: 768px) {
  .slide:first-child img {
    transform: rotate(-25deg);
  }
}

.slide:nth-child(7) img {
  transform: rotate(37deg);
}

.slide:nth-child(2) img {
  transform: rotate(-24deg);
}

@media screen and (max-width: 768px) {
  .slide:nth-child(2) img {
    transform: rotate(0deg);
  }
}

.slide:nth-child(6) img {
  transform: rotate(24deg);
}

.slide:nth-child(3) img {
  transform: rotate(-10deg);
}

@media screen and (max-width: 768px) {
  .slide:nth-child(3) img {
    transform: rotate(25deg);
  }
}

.slide:nth-child(5) img {
  transform: rotate(10deg);
}

.currentStep {
  font-weight: 700;
  color: var(--color-blue-dark);
  top: -5px;
}

.slide img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.activeSlide {
  width: 208px;
  height: 232px;
  background: url("../assets/images/active-slide.svg") no-repeat;
  background-size: 100%;
}

@media screen and (max-width: 1280px) {
  .activeSlide {
    width: 134px;
    height: 146px;
  }
}

.lineContainer {
  width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
}

/*.lineContainer {
  width: calc(100vw * 1.2);
  height: calc(100vw * 0.7);
  left: 50%;
  transform: translateX(-50%);
  border: 8px solid var(--color-yellow);
  border-radius: 50%;
}

@media (max-width: 768px) {
  .lineContainer {
    border-width: 4px;
  }
}*/

.line {
  width: 100%;
  height: auto;
}

.sliderContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1136px;
  margin: 80px auto 0;
}

@media screen and (max-width: 1044px) {
  .sliderContent {
    padding: 0 20px;
    margin: 40px auto 0;
  }
}

.sliderArrows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: var(--transition);
}

.sliderArrows:not(.sliderArrowsDisabled):hover .arrow {
  color: var(--color-blue);
}

.sliderArrowsDisabled .arrow {
  cursor: default;
  color: var(--color-gray-disabled);
}

.prev,
.next {
  width: 36px;
  height: 36px;
}

@media screen and (max-width: 1044px) {
  .prev,
  .next {
    width: 24px;
    height: 24px;
  }
}

.arrow {
  color: var(--color-blue-dark);
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: var(--transition);
}

.prev .arrow {
  transform: rotate(-180deg);
}

.slideTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 480px;
  margin-bottom: 48px;
}

@media screen and (max-width: 1044px) {
  .slideTitleContainer {
    width: 65%;
    max-width: 260px;
    margin-bottom: 35px;
  }
}

.slideDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 0;
  padding-bottom: 20px;
}

.descBox {
  align-items: flex-start;
  width: 100%;
  max-width: 1032px;
  padding: 38px 16px 56px;
}

.descContent {
  padding: 0 57px;
}

@media screen and (max-width: 1280px) {
  .descBox {
    max-width: 768px;
    padding: 10px 16px 16px;
  }

  .descContent {
    padding: 0 36px;
  }
}

@media screen and (max-width: 1044px) {
  .descBox {
    max-width: 500px;
    padding: 10px 16px 16px;
  }

  .descContent {
    padding: 0;
  }
}

@media screen and (max-width: 500px) {
  .descBox {
    max-width: 100%;
  }
}

.descTitle {
  font-size: 25px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 18px;
}

@media screen and (max-width: 1044px) {
  .descTitle {
    font-size: var(--default-font-size);
    line-height: var(--default-line-height);
    margin-bottom: 16px;
  }
  .descText {
    font-size: var(--default-font-size-v2);
    line-height: var(--default-line-height-v2);
  }
}

.btnContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 38px;
}

@media screen and (max-width: 1044px) {
  .btnContainer {
    margin-top: 16px;
  }
}
