.btn {
  display: inline-block;
  font-family: var(--font-family);
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
  font-weight: 600;
  color: var(--color-white);
  text-align: center;
  padding: 24px 48px 28px;
  min-width: 286px;
  height: 83px;
  width: auto;
  border: 1px solid transparent;
  border-radius: 56px;
  box-shadow: 0px 5px 10px rgba(5, 111, 196, 0.2);
  background-color: var(--color-blue-dark);
  cursor: pointer;
  transition: var(--transition);
  position: relative;
}

@media screen and (max-width: 1044px) {
  .btn {
    min-width: 208px;
    min-height: 58px;
    height: auto;
    padding: 18px 30px 16px;
  }
}

.btn:hover,
.btn:focus {
  background-color: var(--color-hover-blue);
  color: var(--color-white);
}

.btn[disabled],
.btn[disabled]:hover,
.btn[disabled]:focus {
  cursor: default;
  background: var(--color-white);
  color: var(--color-gray-disabled);
}

.secondary {
  background-color: var(--color-yellow);
  color: var(--color-blue-dark);
}

.secondary:hover,
.secondary:focus {
  background-color: var(--color-hover-yellow);
  color: var(--color-blue-dark);
}

.link,
.link:hover,
.link:focus {
  color: var(--color-blue-dark);
  background: transparent;
  box-shadow: none;
  min-width: 0;
  min-height: auto;
  width: auto;
  height: var(--default-line-height);
  padding: 0;
}

.link:hover {
  text-decoration: underline;
}

.btnIcon {
  border: 0;
  background: transparent;
  color: var(--color-blue-dark);
  padding: 0;
  width: 38px;
  height: 38px;
  outline: none;
  cursor: pointer;
  transition: var(--transition);
}

.btnIcon:hover {
  color: var(--color-blue);
}

.icon {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1044px) {
  .btnIcon {
    width: 24px;
    height: 24px;
  }
}

.leftIconContainer,
.rightIconContainer {
  width: 38px;
  height: 38px;
  top: 50%;
  transform: translateY(-50%);
}

.leftIconContainer {
  position: absolute;
  left: 77px;
}

.leftIcon {
  color: var(--color-yellow);
}

.secondary .leftIcon {
  color: var(--color-blue-dark);
}

.rightIconContainer {
  position: absolute;
  right: 44px;
}

.arrowIcon {
  fill: var(--color-yellow);
}

.secondary .arrowIcon {
  fill: var(--color-blue-dark);
}

.leftIcon,
.arrowIcon {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1280px) {
  .leftIconContainer {
    left: 38px;
  }

  .rightIconContainer {
    right: 38px;
  }
}

@media screen and (max-width: 1044px) {
  .leftIconContainer {
    left: 12px;
  }

  .rightIconContainer {
    right: 12px;
  }

  .leftIconContainer,
  .rightIconContainer {
    width: 24px;
    height: 24px;
  }
}
