.searchContainer {
  position: relative;
  width: 100%;
}

.suggestions {
  border: 1px solid var(--color-gray);
  list-style: none;
  margin: 0;
  max-height: 160px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  position: absolute;
  z-index: 1;
  background: #ffffff;
  border-radius: 4px;
}

.suggestions li {
  font-family: var(--font-family);
  font-size: var(--default-font-size-v2);
  line-height: var(--default-line-height-v2);
  font-weight: 400;
  color: var(--color-blue-dark);
  padding: 0.5rem;
  cursor: pointer;
}

.suggestionActive,
.suggestions li:hover {
  background-color: var(--color-gray);
}

.noSuggestions {
  font-family: var(--font-family);
  font-size: var(--default-font-size-v2);
  line-height: var(--default-line-height-v2);
  color: var(--color-blue-dark);
  padding: 0.5rem;
}

@media screen and (max-width: 1044px) {
}
