.modalHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 25px 25px 0;
}

.helpIcon {
  width: 38px;
  height: 38px;
  color: var(--color-blue-dark);
}

.modalHeader .title {
  margin: 0;
  padding: 0 48px 0 38px;
}

.closeBtn {
  position: absolute;
  top: 25px;
  right: 25px;
}

@media screen and (max-width: 1044px) {
  .modalHeader {
    padding: 16px 16px 0;
  }

  .helpIcon {
    width: 24px;
    height: 24px;
  }

  .title {
    padding: 0 34px 0 10px;
  }

  .closeBtn {
    top: 16px;
    right: 16px;
  }
}
