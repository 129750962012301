.rangeLinesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: 30px;
}

.stepsContainer {
  display: flex;
  justify-content: space-between;
  margin: 0;
  position: absolute;
  height: 100%;
  width: calc(100% + (50px + (17px * 2)));
  left: calc((25px + 17px) * -1);
  top: -30px;
}

.step {
  flex: 1;
  font-weight: 600;
  color: var(--color-gray-dark);
  text-align: center;
  min-width: 38px;
  position: relative;
}

.step:first-child,
.step:last-child {
  color: var(--color-gray);
  top: 45px;
}

.step:nth-child(2),
.step:nth-child(6) {
  color: var(--color-gray-disabled);
  top: 25px;
}

.step:nth-child(3),
.step:nth-child(5) {
  color: var(--color-gray-disabled);
  top: 10px;
}

.lineImg {
  width: 100%;
  height: auto;
}

.currentStep {
  font-weight: 700;
  color: var(--color-blue-dark);
  top: -5px;
}

@media screen and (max-width: 1044px) {
  .rangeLinesContainer {
    margin-top: 25px;
  }

  .stepsContainer {
    width: calc(100% + (32px + (11px * 2)));
    left: calc((16px + 11px) * -1);
    top: -15px;
  }

  .step {
    font-size: 10px;
    line-height: 13px;
    min-width: 25px;
  }

  .currentStep {
    font-size: var(--default-font-size-v2);
    line-height: var(--default-line-height-v2);
  }
}
