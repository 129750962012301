.configContainer {
  width: 100%;
}

.configParamsHead {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 10px;
}

.headItem {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  padding: 0 20px 0;
  min-width: 213px;
  text-align: center;
}

.headItem + .headItem {
  padding: 0 10px 0;
  min-width: 213px;
}

.configParamsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.configParamsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 18px 0;
}

@media (max-width: 1280px) {
  .headItem {
    min-width: 206px;
  }

  .headItem + .headItem {
    min-width: 190px;
  }
}

@media screen and (max-width: 1044px) {
  .configParamsItemTitle {
    margin-bottom: 15px;
    width: 100%;
  }

  .configParamsItem:first-child {
    padding-top: 0;
  }

  .configParamsItem {
    flex-direction: column;
    align-items: flex-start;
  }

  .productActionContainer {
    margin-left: 5px;
  }
}

.configParamsItemTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-blue-dark);
  margin-bottom: 0;
}

.titleContent {
  display: flex;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 1044px) {
  .configParamsItemTitle {
    margin-bottom: 24px;
    width: 100%;
  }
}

@media screen and (max-width: 390px) {
  .titleContent {
    max-width: calc(100% - 29px);
  }

  .titleContent span {
    max-width: calc(100% - 29px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.configParamsItemNotActive .configParamsItemTitle {
  color: var(--color-gray-disabled);
}

.iconContainer {
  display: flex;
  flex: 0 0 38px;
  width: 38px;
  height: 38px;
  cursor: pointer;
  color: var(--color-yellow);
  transition: var(--transition);
}

.productReadMore {
  margin-left: 10px;
}

@media screen and (max-width: 1044px) {
  .productReadMore {
    margin-left: 7px;
  }

  .iconContainer {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
  }
}

.iconContainer:hover {
  color: var(--color-blue);
}

.configParamsItemNotActive .iconContainer:not(.productActionContainer),
.configParamsItemNotActive .iconContainer:not(.productActionContainer):hover {
  color: var(--color-gray-disabled);
  cursor: default;
}

.icon {
  width: 100%;
  height: auto;
}

.addIcon {
  transform: rotate(-45deg);
}

.configParamsItemValues {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1044px) {
  .configParamsItemValues {
    width: 100%;
    align-items: flex-start;
  }
}

.priceContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  padding-left: 20px;
  min-width: 213px;
}

.paddingRight {
  padding-right: 38px;
}

.rangeContainer {
  min-width: 213px;
}

.rangeItemsContainer {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1280px) {
  .priceContainer {
    min-width: 190px;
  }

  .rangeContainer {
    min-width: 190px;
  }
}

@media screen and (max-width: 1044px) {
  .priceContainer {
    padding-left: 0;
    flex-direction: column;
    min-width: unset;
  }

  .paddingRight {
    padding-right: 0;
  }

  .rangeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: unset;
  }

  .rangeItemsContainer {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 460px) {
  .rangeItemsContainer {
    flex-direction: column;
  }
}

.totalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--secondary-font-family);
  font-weight: 600;
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  color: var(--color-blue-dark);
  background: var(--color-gray);
  border-radius: 32px;
  padding: 0 15px;
  cursor: default;
  min-width: 120px;
  height: 46px;
  margin-right: calc(10px + 38px);
}

.priceContainer .totalContainer {
  margin-right: 10px;
}

.configParamsItemNotActive .totalContainer {
  color: var(--color-gray-disabled);
}

@media screen and (max-width: 1044px) {
  .totalContainer {
    min-width: 80px;
    height: 37px;
    padding: 0 12px;
    margin: 0 15px;
  }

  .priceContainer .totalContainer {
    margin: 0 15px;
  }
}

.freeTrial {
  text-align: right;
  font-weight: 600;
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
  padding-right: calc(15px + 30px);
}

.totalPriceContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  margin-top: 30px;
  width: 100%;
}

.totalPriceContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  margin-top: 38px;
  width: 100%;
}

@media screen and (max-width: 1044px) {
  .freeTrial {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    padding-right: 0;
  }

  .totalPriceContainer {
    margin-top: 24px;
  }

  .priceHint {
    margin-left: 10px;
  }
}

.arrowsClass {
  color: var(--color-yellow);
}

.priceHint {
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  margin-bottom: 4px;
}

.configParamsItemNotActive .priceHint {
  color: var(--color-gray-disabled);
}

.radioMonthesContainer {
  padding: 15px 0 10px;
}

.mandarotyMonthesSelect {
  font-family: var(--secondary-font-family);
  color: var(--color-blue-dark);
  background: var(--color-gray);
  border-radius: 32px;
  padding: 0 5px;
  width: 85px;
  height: 46px;
  margin: 0 10px;
  outline: none;
  border: 0;
  font-weight: 600;
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
}

.configParamsItemNotActive .mandarotyMonthesSelect,
.configParamsItemNotActive .mandarotyMonthesSelect:hover {
  color: var(--color-gray-disabled);
}

@media (max-width: 1280px) {
  .mandarotyMonthesSelect {
    width: 75px;
  }
}

@media screen and (max-width: 1044px) {
  .mandarotyMonthesSelect {
    margin: 0 10px 0 0;
    width: 70px;
    height: 37px;
  }
}

@media screen and (max-width: 460px) {
  .mandarotyMonthesSelect {
    margin: 0 10px 10px 0;
  }
}
