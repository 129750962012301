.blockContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: var(--container-lg-width-fixed);
  padding: 70px 0 0;
}

.titleContainer {
  position: relative;
  width: 100%;
}

.title {
  margin-bottom: 19px;
}

.closeBtn {
  position: absolute;
  top: 6px;
  right: 6px;
}

@media screen and (max-width: 1044px) {
  .closeBtn {
    top: 6px;
    right: 6px;
  }
}

.linksContainer {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 38px 73px;
  margin-bottom: 70px;
}

.linkTitle {
  font-weight: 700;
}

.linksUl {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.linksUl li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
}

.linksUl li:first-child {
  padding-top: 0;
}

.icon {
  width: 51px;
  height: 51px;
  color: var(--color-yellow);
  transition: var(--transition);
}

.link {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: 400;
  color: var(--color-blue-dark);
  text-decoration: none;
  transition: var(--transition);
}

.link:hover {
  text-decoration: underline;
}

.link:hover .icon {
  color: var(--color-blue-dark);
}

.linkContent {
  padding: 6px 0 0 36px;
  text-align: left;
}

.noContent {
  width: 100%;
  padding: 32px 0 48px;
}

@media screen and (max-width: 1044px) {
  .blockContainer {
    padding: 24px 0;
  }

  .linksContainer {
    padding: 16px;
    margin-bottom: 32px;
  }

  .title {
    margin-bottom: 24px;
  }

  .subTitle {
    margin-bottom: 24px;
  }

  .linksUl li {
    padding: 8px 0;
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  .linkContent {
    padding: 0 0 0 16px;
  }

  .noContent {
    padding: 16px 0 32px;
  }
}
