.productContainer {
  display: flex;
  width: 100%;
  max-width: 1260px;
  margin: 35px 0;
}

@media screen and (max-width: 1280px) {
  .productContainer {
    width: calc(100% - 48px);
    margin: 24px 0;
  }
}

@media screen and (max-width: 1044px) {
  .productContainer {
    width: calc(100% - 48px);
  }
}

@media screen and (max-width: 768px) {
  .productContainer {
    width: 100%;
  }
}

.productTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentBox {
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
}

@media screen and (max-width: 1044px) {
  .contentBox {
    padding: 16px;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  gap: 5px;
}

.title {
  font-weight: 700;
  display: flex;
  flex: 1;
  padding-right: 15px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}

.productReadMore {
  display: flex;
  flex: 0 0 38px;
  width: 38px;
  height: 38px;
  cursor: pointer;
  color: var(--color-blue-dark);
}

.productReadMore:hover {
  color: var(--color-blue);
}

.helpIcon {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1044px) {
  .productReadMore {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
  }
}

.contentBox h5 {
  font-family: inherit;
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: 600;
  color: var(--product-text-color);
  text-align: center;
  padding-top: 10px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .contentBox h4 {
    font-size: 28px;
    line-height: 32px;
  }
}
