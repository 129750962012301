.personInput {
  max-width: 550px;
  width: 100%;
}

.otherPersonContainer {
  padding-bottom: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.otherPersonInputsRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  gap: 25px;
}

.otherPersonInput {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  gap: 25px;
}

.otherPersonRemove {
  display: flex;
  align-items: center;
  height: 73px;
}

.iconContainer {
  display: flex;
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: var(--color-blue);
  transition: var(--transition);
}

.icon {
  width: 100%;
  height: auto;
}

.small {
  padding: 5px 15px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
  height: auto;
  min-height: 32px;
  min-width: 0;
  margin-bottom: 10px;
}

@media screen and (max-width: 1044px) {
  .otherPersonContainer {
    gap: 15px;
  }

  .otherPersonInputsRow {
    align-items: center;
  }

  .otherPersonInput {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 0;
    width: 100%;
  }

  .otherPersonRemove {
    margin-top: -25px;
  }
}
