.sliderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sliderContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
}

.bulletsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 36px;
}

@media (max-width: 1044px) {
  .bulletsContainer {
    margin-bottom: 24px;
  }
}

.bullet {
  width: 19px;
  height: 19px;
  background: #d0cbcb;
  box-shadow: 0px 4px 10px rgba(39, 45, 84, 0.05),
    inset 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin: 0 9px;
  transition: all 600ms ease;
  cursor: pointer;
}

@media (max-width: 1044px) {
  .bullet {
    width: 12px;
    height: 12px;
    margin: 0 6px;
    border: 1px solid var(--color-white);
  }
}

.bullet.activeBullet {
  background: var(--color-blue-dark);
  box-shadow: 0px 4px 8px rgba(39, 45, 84, 0.3);
  opacity: 0.9;
}

.slidesContainer {
  display: flex;
  width: 100%;
  max-width: 768px;
  overflow: hidden;
}

@media (max-width: 1044px) {
  .slidesContainer {
    max-width: 486px;
  }
}

@media (max-width: 768px) {
  .slidesContainer {
    max-width: 100%;
  }
}

.slides {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 300px;
  transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media (max-width: 1044px) {
  .slides {
    height: 212px;
  }
}

.sliderArrows {
  position: absolute;
  left: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sliderArrows:not(.sliderArrowsDisabled):hover .arrow {
  color: var(--color-blue);
}

.sliderArrowsDisabled .arrow {
  cursor: default;
  color: var(--color-gray-disabled);
}

.sliderArrowsNext {
  left: auto;
  right: 0;
}

.arrow {
  color: var(--color-blue-dark);
  width: 36px;
  height: 36px;
  cursor: pointer;
  transition: var(--transition);
}

@media (max-width: 768px) {
  .sliderArrows {
    display: none;
  }
}

.prev .arrow {
  transform: rotate(-180deg);
}

.slideDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 0;
  margin-top: -1px;
}

@media (max-width: 1280px) {
  .slideDescription {
    padding: 0 20px;
  }
}

@media (max-width: 1044px) {
  .slideDescription {
    margin-top: -2px;
  }
}

.btnContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 43px 88px;
  background: radial-gradient(
    35.47% 35.47% at 50.15% 40%,
    rgba(222, 223, 108, 0.9) 0%,
    rgba(222, 223, 108, 0.26) 100%
  );
  border: 1px solid var(--color-yellow);
  box-shadow: 0px 4px 10px rgba(39, 45, 84, 0.1);
  border-radius: 16px;
}

@media (max-width: 1044px) {
  .btnContainer {
    padding: 16px 12px;
  }
}

.btn + .btn {
  margin-top: 25px;
}

@media (max-width: 1044px) {
  .btn + .btn {
    margin-top: 12px;
  }
}
