.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 99;
}

.modal/*.enterDone */ {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modalContent {
  width: 1039px;
  min-height: 458px;
  max-height: 100%;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  transform: translateX(-50%) translateY(-100%);
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 20px 30px;
  overflow-y: auto;
}

@media (max-width: 1070px) {
  .modalContent {
    width: 90%;
    max-width: 768px;
  }
}

.modalContent.small {
  width: 380px;
  min-height: 420px;
}

@media (max-width: 390px) {
  .modalContent.small {
    width: 90%;
    max-width: 380px;
  }
}

.modal/*.enterDone*/ .modalContent {
  transform: translateX(-50%) translateY(-50%);
}

.modal.exit .modalContent {
  transform: translateX(-50%) translateY(-100%);
}

.modalHeader,
.modalFooter {
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modalTitle {
  margin: 0 0 20px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  font-family: var(--secondary-font-family);
  color: var(--main-theme-color);
}

.modalBody {
}

.closeX {
  background: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 0;
  opacity: 0.8;
  margin-right: -10px;
}

.closeX svg {
  fill: var(--default-text-color);
}

.closeX:hover {
  opacity: 1;
}
