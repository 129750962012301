.questionContent {
  font-family: var(--font-family);
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  color: var(--color-blue-dark);
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 16px;
  width: 100%;
}

.questionItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
}

.questionItem .h3 {
  margin-top: 0;
}

.answersContainer {
  width: 100%;
  /* padding-left: 16px; */
}

.answerItem {
  margin: 5px 0;
  padding-left: 15px;
  color: var(--color-blue-dark);
}

.answerText {
  padding-left: 75px;
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
}

.innerQuestions {
  padding-top: 16px;
}

.errorTip {
  color: red;
  font-size: 14px;
  font-weight: 400;
  padding-left: 21px;
}
