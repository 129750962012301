.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.headerStickyPadding {
  padding-top: var(--header-height);
}
