.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--header-height);
  background-color: var(--color-blue-dark);
  color: var(--color-white);
  font-family: var(--secondary-font-family);
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  position: relative;
}

.header:hover {
  color: var(--color-white);
}

@media (max-width: 1280px) {
  .header {
    font-size: 18px;
    line-height: 21.6px;
  }
}

.headerSticky {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
  /* height: 68px; */
  height: 54px;
  pointer-events: none;
}

@media (max-width: 1044px) {
  .logo {
    height: 23px;
  }
}

.navContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 1044px) {
  .navContainer {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: calc(100vh - var(--header-height));
    background: rgba(233, 233, 234, 0.7);
    backdrop-filter: blur(20px);
    padding: 32px;
    overflow-y: auto;
    position: fixed;
    top: var(--header-height);
    left: 0;
    z-index: 5;
  }
}

.navText {
  font-family: var(--secondary-font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: var(--color-blue-dark);
  text-align: center;
  margin-bottom: 34px;
}

.navBoldText,
.closeBtn {
  font-family: var(--secondary-font-family);
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-blue-dark);
  text-align: center;
}

.navBoldText {
  margin-bottom: 44px;
}

.closeBtn {
  font-size: 16px;
  line-height: 19px;
}

.nav {
  display: flex;
}

@media (max-width: 1044px) {
  .nav {
    width: 100%;
  }
}

.ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 1044px) {
  .ul {
    flex-direction: column;
    width: 100%;
  }
}

.li + .li {
  margin-left: 44px;
}

@media (max-width: 1044px) {
  .li {
    width: 100%;
    margin: 0 0 36px 0;
  }

  .li + .li {
    margin-left: 0;
  }
}

.loginBtn,
.logoutBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-white);
  cursor: pointer;
  text-decoration: none;
  transition: var(--transition);
}

.loginBtn:hover,
.logoutBtn:hover {
  color: var(--color-yellow);
  text-decoration: none;
}

.logoutBtn,
.logoutBtn:hover {
  border: none;
  background: none;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  align-self: center;
}

.signIconContainer {
  display: flex;
  align-self: center;
  /* padding-right: 10px; */
  padding-right: 8px;
}

.signIcon {
  color: var(--color-white);
  width: 25px;
  height: 25px;
  transition: var(--transition);
}

.loginBtn:hover .signIcon,
.logoutBtn:hover .signIcon {
  color: var(--color-yellow);
}

.logoutBtn .signIconContainer .signIcon {
  transform: rotate(180deg);
}

.li .logoutIcon {
  transform: translateY(-50%) rotate(180deg);
}

@media (max-width: 1044px) {
  .li .icon {
    left: 27px;
  }
}
