.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 -35px;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: transparent;
  opacity: 0.5;
  border-radius: 18px;
}

@media (max-width: 1280px) {
  .container {
    margin: 0 -24px;
  }
}

@media (max-width: 768px) {
  .container {
    margin: 0;
  }
}
