.loaderContainer {
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.loader {
  display: inline-block;
  width: 90px;
  height: 90px;
}

.loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--color-blue);
  border-color: var(--color-blue) transparent var(--color-blue) transparent;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1044px) {
  .loader {
    width: 56px;
    height: 56px;
  }

  .loader:after {
    width: 38px;
    height: 38px;
    margin: 4px;
    border-width: 5px;
  }
}
