.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fefefe;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 0.56) 0%,
    rgba(255, 255, 255, 0.16) 100%
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255, 0.56) 0%,
    rgba(255, 255, 255, 0.16) 100%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 0.56) 0%,
    rgba(255, 255, 255, 0.16) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8fffffff', endColorstr='#29ffffff',GradientType=1 );
  /* background: radial-gradient(
    97.01% 103.71% at 0% 3.4%,
    rgba(255, 255, 255, 0.56) 0%,
    rgba(255, 255, 255, 0.08) 100%,
    rgba(255, 255, 255, 0.16) 100%
  ); */
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(39, 45, 84, 0.1);
  backdrop-filter: blur(12px);
  border-radius: 16px;
  padding: 16px 16px 56px;
  position: relative;
}
