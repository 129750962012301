:root {
  --btn-border-radius: 26.5px;
  --btn-padding: 13px 17px;
  --btn-sm-padding: 13px 17px;
  --body-max-width: 100%;
  --container-lg-width: 100%;
  /* --container-lg-width-fixed: 1575px; */
  --container-lg-width-fixed: 1260px;

  --letter-spacing: -0.017em;

  /* NEW VARIABLES */
  --font-family: "Open Sans", sans-serif;
  --secondary-font-family: "Lato", sans-serif;

  /* original sizes */
  /* 
  --h1-font-size: 64px;
  --h1-line-height: 76.8px;
  --h2-font-size: 48px;
  --h2-line-height: 57.6px;
  --h3-font-size: 36px;
  --h3-line-height: 49.03px;
  --default-font-size: 28px;
  --default-line-height: 38.13px;
  --default-font-size-v2: 28px;
  --default-line-height-v2: 38.13px;
  --small-font-size: 24px;
  --small-line-height: 32.68px;

  --header-height: 136px; 
  */

  --h1-font-size: 51px;
  --h1-line-height: 61px;
  --h2-font-size: 38px;
  --h2-line-height: 46px;
  --h3-font-size: 29px;
  --h3-line-height: 39px;
  --default-font-size: 22px;
  --default-line-height: 30px;
  --default-font-size-v2: 22px;
  --default-line-height-v2: 30px;
  --small-font-size: 19px;
  --small-line-height: 26px;

  --header-height: 108px;

  --color-violet-dark: #4a4cb9;
  --color-violet-light: #6f74b2;
  --color-blue-dark: #272d54;
  --color-blue: #056fc4;
  --color-hover-blue: #5862a1;
  --color-yellow: #dedf6c;
  --color-hover-yellow: #f4f4b5;
  --color-white: #ffffff;
  --color-gray-dark: #585857;
  --color-gray: #e9e9ea;
  --color-gray-disabled: #b6b5b5;

  --default-text-color: var(--color-blue-dark);
  --transition: all 100ms ease;
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  min-height: 100%;
  padding: 0;
}

body {
  font-family: var(--font-family);
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--default-text-color);
  min-width: 360px;
  max-width: var(--body-max-width);
  margin: 0 auto;
  background: var(--main-bg-color);
  background: linear-gradient(
    180deg,
    rgba(226, 226, 226, 0.8) 0%,
    rgba(207, 218, 226, 0) 100%
  );
}

h1,
.h1 {
  font-family: var(--secondary-font-family);
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
  font-weight: 900;
  margin: 0 0 38px 0;
}

h2,
.h2 {
  font-family: var(--secondary-font-family);
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  font-weight: 900;
  margin: 0 0 38px 0;
}

h3,
.h3 {
  font-family: var(--font-family);
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: 600;
  margin: 0 0 38px 0;
}

a {
  color: var(--color-blue-dark);
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: var(--default-text-color);
}

b {
  font-weight: 600;
}

.react-datepicker-wrapper {
  width: 100%;
}

body .react-datepicker-popper {
  z-index: 5;
}

.Toastify .Toastify__toast-body {
  font-size: 16px;
  line-height: 24px;
}

.tooltip {
  background: var(--color-blue) !important;
  color: var(--color-white) !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.tooltip.place-top:after {
  border-top-color: var(--color-blue) !important;
}

.tooltip.place-left:after {
  border-left-color: var(--color-blue) !important;
}

.tooltip.place-right:after {
  border-right-color: var(--color-blue) !important;
}

.tooltip.place-bottom:after {
  border-bottom-color: var(--color-blue) !important;
}

.tooltip.show {
  opacity: 1 !important;
}

@media (max-width: 1280px) {
  :root {
    --h1-font-size: 40px;
    --h1-line-height: 48px;
    --h2-font-size: 30px;
    --h2-line-height: 36px;
    --h3-font-size: 23px;
    --h3-line-height: 31px;
    --default-font-size: 17px;
    --default-line-height: 24px;
    --default-font-size-v2: 17px;
    --default-line-height-v2: 24px;
    --small-font-size: 15px;
    --small-line-height: 20px;

    --header-height: 86px;
    --container-lg-width-fixed: 1024px;
  }
}

@media (max-width: 1044px) {
  :root {
    --h1-font-size: 24px;
    --h1-line-height: 28.8px;
    --h2-font-size: 20px;
    --h2-line-height: 24px;
    --h3-font-size: 16px;
    --h3-line-height: 21.79px;
    --default-font-size: 16px;
    --default-line-height: 21.79px;
    --default-font-size-v2: 14px;
    --default-line-height-v2: 19.07px;
    --small-font-size: 12px;
    --small-line-height: 16.34px;

    --header-height: 46px;
    --container-lg-width-fixed: 768px;
  }

  h1,
  .h1 {
    margin-bottom: 24px;
  }

  h2,
  .h2 {
    margin-bottom: 16px;
  }

  h3,
  .h3 {
    margin-bottom: 16px;
  }
}

@media (max-width: 768px) {
  :root {
    --h1-font-size: 24px;
    --h1-line-height: 28.8px;
    --h2-font-size: 20px;
    --h2-line-height: 24px;
    --h3-font-size: 16px;
    --h3-line-height: 21.79px;
    --default-font-size: 16px;
    --default-line-height: 21.79px;
    --default-font-size-v2: 14px;
    --default-line-height-v2: 19.07px;
    --small-font-size: 12px;
    --small-line-height: 16.34px;

    --header-height: 46px;
    --container-lg-width-fixed: 100%;
  }

  h1,
  .h1 {
    margin-bottom: 24px;
  }

  h2,
  .h2 {
    margin-bottom: 16px;
  }

  h3,
  .h3 {
    margin-bottom: 16px;
  }
}

@media (min-width: 640px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1280px) {
}
