.btnContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textBlock {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: 600;
  margin: 0;
}

.smallTextBlock {
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  margin: 32px 0 0 0;
}

.twoDivs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.half {
  flex: 1;
}

@media (max-width: 1044px) {
  .half {
    width: 100%;
  }
  .half + .half {
    display: none;
  }
}

/* Block one */
.blockOneContainer {
  width: 100%;
  padding: 70px 0 0 0;
}

div.blockOne {
  padding: 44px 61px;
  justify-content: center;
  align-items: center;
}

.blockOne .h1 {
  width: 100%;
}

.blockOne .textBlock {
  margin-top: 19px;
}

.blockOne .smallTextBlock {
  display: flex;
  align-items: center;
}

.blockOne .smallTextBlock img {
  width: 38px;
  height: 38px;
}

.blockOne .smallTextBlock span {
  padding: 4px 0 0 17px;
}

.blockOne .btnContainer {
  margin-top: 51px;
}

.blockOne .btnContainer a + a {
  margin-top: 25px;
}

@media (max-width: 1044px) {
  .blockOneContainer {
    padding: 24px 0 0 0;
  }

  div.blockOne {
    align-items: flex-start;
    padding: 32px 16px;
  }

  .blockOne .textBlock {
    margin-top: 16px;
  }

  .blockOne .smallTextBlock {
    margin-top: 32px;
    align-items: flex-start;
    padding: 0 10px;
  }

  .blockOne .smallTextBlock img {
    width: 24px;
    height: auto;
  }

  .blockOne .smallTextBlock span {
    padding: 0 0 0 16px;
  }

  .blockOne .btnContainer {
    margin-top: 36px;
  }

  .blockOne .btnContainer a + a {
    margin-top: 12px;
  }
}

/* Block two */
.blockTwoContainer {
  width: 100%;
  padding: 70px 0 0 0;
}

div.blockTwo {
  padding: 38px 25px 44px;
  justify-content: center;
  align-items: center;
}

.blockTwoContainer .half + .half {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 100px;
}

.blockTwoImg {
  max-width: 396px;
}

.blockTwo .btnContainer {
  margin-top: 32px;
}

@media (max-width: 1044px) {
  .blockTwoContainer {
    padding: 48px 0 0 0;
  }

  div.blockTwo {
    align-items: flex-start;
    padding: 16px;
  }

  .blockTwoContainer .half + .half {
    display: none;
  }

  .blockTwo .btnContainer {
    margin-top: 16px;
  }
}

/* Block three */
.withoutPadding {
  padding: 0;
}

.blockThreeContainer {
  width: 100%;
  padding: 137px 0 0 0;
}

.widthBottomPadding {
  padding-bottom: 70px;
}

@media (max-width: 1280px) {
  .blockThreeContainer .half {
    padding: 0 20px;
  }
}

.blockThreeContainer .blockSlider {
  max-width: 1136px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 1044px) {
  .blockThreeContainer {
    padding: 48px 0 0 0;
  }

  .blockThreeContainer .blockSlider {
    max-width: 768px;
  }
}

.bankLogoContainer {
  margin-top: 38px;
}

/* Block four */
.blockFourContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 137px 0 80px 0;
}

.blockFour {
  width: 100%;
  display: flex;
  max-width: var(--container-lg-width-fixed);
  flex-direction: column;
  justify-content: flex-start;
}

.blockFourContainer .blockSlider {
  width: 100%;
}

@media (max-width: 1280px) {
  .blockFour {
    padding: 0 20px;
  }
}

@media (max-width: 1044px) {
  .blockFourContainer {
    padding: 48px 0;
  }

  .blockFour {
    align-items: flex-start;
    padding: 0 20px;
  }
}

/* Block five */
.blockFiveContainer {
  width: 100%;
  padding: 117px 0;
}

.blockFiveContainer .textBlock + .textBlock {
  margin-top: 38px;
}

.formContainer {
  width: 100%;
  max-width: 821px;
  margin: 57px auto 0;
}

@media (max-width: 1044px) {
  .blockFiveContainer {
    padding: 48px 0;
  }
}
