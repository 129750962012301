.label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.checkbox {
  display: flex;
  flex: 0 0 38px;
  width: 38px;
  height: 38px;
  cursor: pointer;
}

.disabled,
.disabled .checkbox {
  cursor: default;
}

.checkbox input[type="checkbox"] {
  display: none;
}

.checkbox .knob {
  border: 1px solid var(--color-gray-dark);
  background: var(--color-gray);
  border-radius: 8px;
  display: block;
  width: 100%;
  height: 100%;
  transition: var(--transition);
}

.checkbox:hover .knob {
  border-color: var(--color-yellow);
  background-color: var(--color-gray);
}

.checkbox input[type="checkbox"]:checked + .knob,
.waitCheck.knob {
  border-color: var(--color-white);
  background-color: var(--color-yellow);
}

.checkbox input[type="checkbox"]:checked + .knob:not(.waitCheck):after {
  background-image: url("../assets/images/checkbox-check.svg");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
}

.disabled:hover .knob,
.disabled .knob {
  border-color: var(--color-white);
  background-color: var(--color-gray);
  cursor: default;
}

.waitCheck {
  opacity: 0.4;
}

.text {
  padding-left: 36px;
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
}

@media screen and (max-width: 1044px) {
  .label {
    margin-bottom: 16px;
  }

  .checkbox {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
  }

  .text {
    padding-left: 8px;
  }
}
