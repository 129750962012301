.productContainer {
  display: flex;
  width: 665px;
  min-height: 740px;
  padding: 35px;
}

@media screen and (max-width: 1280px) {
  .productContainer {
    flex-grow: 1;
    width: calc(50% - 24px);
    min-height: 448px;
    padding: 24px;
  }
}

@media screen and (max-width: 1044px) {
  .productContainer {
    flex-grow: 0;
    width: calc(100% - 48px);
  }
}

@media screen and (max-width: 768px) {
  .productContainer {
    flex-grow: 1;
    width: 100%;
    min-height: 448px;
    height: auto;
    padding: 24px 0;
  }
}

.contentBox {
  padding: 25px 25px 36px;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
}

@media screen and (max-width: 1044px) {
  .contentBox {
    padding: 16px 16px 23px;
  }
}

.productTop {
  width: 100%;
}

.statusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 29px;
  height: 38px;
}

@media screen and (max-width: 1044px) {
  .statusContainer {
    margin-bottom: 24px;
    height: 24px;
  }
}

.status {
  color: var(--color-gray-disabled);
  width: 145px;
  padding: 0 25px;
}

@media screen and (max-width: 1044px) {
  .status {
    width: 102px;
    padding: 0 32px;
    font-size: 10px;
    line-height: 13px;
  }
}

.status.active {
  color: var(--color-blue-dark);
  font-weight: 700;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 25px 0;
  gap: 5px;
}

@media screen and (max-width: 1044px) {
  .titleContainer {
    margin: 0 0 14px 0;
  }
}

.title {
  font-weight: 700;
  display: flex;
  flex: 1;
  padding-right: 15px;
  margin: 0;
  text-align: center;
}

.productReadMore {
  display: flex;
  flex: 0 0 38px;
  width: 38px;
  height: 38px;
  cursor: pointer;
  color: var(--color-blue-dark);
}

@media screen and (max-width: 1044px) {
  .productReadMore {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
  }
}

.productReadMore:hover {
  color: var(--color-blue);
}

.productNotActive .productReadMore,
.productNotActive .productReadMore:hover {
  color: var(--color-blue-dark);
  cursor: default;
}

.helpIcon {
  width: 100%;
  height: auto;
}

.productContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.pricePeriod {
  font-weight: 600;
}

@media screen and (max-width: 1044px) {
  .pricePeriod {
    font-size: 10px;
    line-height: 13px;
  }
}

.rangeContainer > .pricePeriod {
  margin-bottom: 5px;
}

@media screen and (max-width: 1044px) {
  .rangeContainer > .pricePeriod {
    margin-bottom: 4px;
  }
}

.rangeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.rangeLinesContainer {
  margin-top: 36px;
}
.rangeLinesContainer svg {
  transition: all 0.8s linear;
  transform: translateX(0);
}
.transformToLeft svg {
  transform: translateX(-7%);
  transition: all 0.8s linear;
}
.transformToRight svg {
  transform: translateX(7%);
  transition: all 0.8s linear;
}
@media screen and (max-width: 1044px) {
  .rangeLinesContainer {
    margin-top: 17px;
  }
}

.priceContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -10px;
}

@media screen and (max-width: 1044px) {
  .priceContainer {
    margin-top: -20px;
  }
}

.productPrice {
  display: flex;
  align-items: center;
  font-family: var(--secondary-font-family);
  font-weight: 900;
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  color: var(--color-blue-dark);
  background: var(--color-gray);
  border-radius: 32px;
  padding: 0 16px;
  height: 41px;
}

@media screen and (max-width: 1044px) {
  .productPrice {
    font-size: 12px;
    line-height: 14px;
    padding: 0 8px;
    height: 22px;
  }
}

.priceContainer .pricePeriod {
  margin-top: 9px;
}

@media screen and (max-width: 1044px) {
  .priceContainer .pricePeriod {
    margin-top: 4px;
  }
}

.productNotActive .titleContainer,
.productNotActive .rangeContainer,
.productNotActive .rangeLinesContainer,
.productNotActive .priceContainer,
.productNotActive .pricePeriod,
.productNotActive .radioMonthes {
  opacity: 0.5;
  filter: blur(2px);
}

.radioMonthesContainer {
  padding: 10px 0 15px;
}

.radioMonthes {
  display: flex;
  justify-content: space-around;
  padding-top: 15px;
}

.radioMonthes .radioLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.radioLabel > div:not(.radioText) {
  width: 25px;
  height: 25px;
  flex: 0 0 25px;
}

.radioLabel .radioText {
  padding-left: 0;
}

.radioContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 12px;
  line-height: 1;
}

.radioContent > span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 14px;
  line-height: 1;
}

.radioContent b {
  font-size: 20px;
  line-height: 1.2;
}

@media screen and (max-width: 1044px) {
  .radioContent b {
    font-size: 16px;
  }
}
