.shopItem {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 44px;
  position: relative;
}

.icon {
  width: 83px;
  height: 83px;
}

.checkedIcon {
  width: 48px;
  height: 48px;
  position: absolute;
  top: -8px;
  right: -10px;
  color: green;
}

.shopTitle {
  text-align: center;
  margin: 0;
}

@media screen and (max-width: 1044px) {
  .shopItem {
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-bottom: 24px;
  }

  .imgContainer {
    margin: 0 16px 0 0;
  }

  .icon {
    width: 40px;
    height: 40px;
  }

  .checkedIcon {
    width: 24px;
    height: 24px;
    top: -3px;
    right: -7px;
  }

  .shopTitle {
    text-align: left;
  }

  .shopTitle br {
    display: none;
  }
}
