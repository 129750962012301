.formGroupInline {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row;
  max-width: 800px;
  width: 100%;
}

.label {
  max-width: 360px;
  width: 100%;
  margin-bottom: 0;
}

.fixedLabel {
  width: 300px;
}

.input {
  max-width: 400px;
  width: 100%;
}

.fixedInput {
  width: 255px;
}

@media screen and (max-width: 1044px) {
  .formGroupInline {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .label {
    max-width: none;
    margin-bottom: 16px;
  }

  .fixedLabel {
    width: 100%;
  }

  .input {
    max-width: none;
    width: 100%;
  }

  .fixedInput {
    width: 100%;
  }
}

.formGroupInline .smallInput {
  width: 130px;
  flex: 0 0 130px;
}

.textCenter input {
  text-align: center;
}

.formGroupInlineLeftMargin {
  justify-content: flex-end;
  margin-left: 14px;
}

@media screen and (max-width: 1044px) {
  .formGroupInline .smallInput {
    width: 100%;
    flex: 0 0 100%;
  }

  .textCenter input {
    text-align: left;
  }

  .formGroupInlineLeftMargin {
    justify-content: flex-start;
    margin-left: 0;
  }
}
