.blockContainer {
  padding: 70px 0;
}

.block {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 38px 73px;
}

@media screen and (max-width: 1044px) {
  .blockContainer {
    padding: 24px 0;
  }

  .block {
    padding: 24px 16px 32px;
  }
}

@media screen and (max-width: 1044px) {
  .title {
    margin-bottom: 16px;
  }
}

.configHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 1044px) {
  .configHeader {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.bottomHeader {
  margin-top: 55px;
  padding-top: 30px;
  border-top: 1px solid #e8e8e8;
}

@media screen and (max-width: 1044px) {
  .bottomHeader {
    margin-top: 20px;
  }
}

.changeBtn {
  display: flex;
  align-items: center;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  color: var(--color-blue-dark);
  transition: var(--transition);
}

.changeBtn:hover {
  color: var(--color-blue);
}

.btnIcon {
  width: 28px;
  height: 28px;
}

.changeBtn span {
  padding-left: 12px;
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
}

@media screen and (max-width: 1044px) {
  .btnIcon {
    width: 24px;
    height: 24px;
  }

  .changeBtn span {
    padding-left: 8px;
  }
}

.dataContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.dataItemsHalf {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 50%;
  width: 50%;
}

.dataContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.dataItem {
  flex: 0 0 50%;
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
  color: var(--color-gray-dark);
}

.dataItem span:not(.error) {
  font-weight: 600;
  color: var(--color-blue-dark);
}

@media screen and (max-width: 1044px) {
  .dataContainer {
    flex-direction: column;
  }

  .dataItemsHalf {
    flex: 0 0 100%;
    width: 100%;
  }

  .dataContent {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  .dataItem {
    flex: 0 0 100%;
  }

  .dataItem span:not(.error) {
    display: block;
    padding-bottom: 8px;
  }
}

.capitalLetter:first-letter {
  text-transform: capitalize;
}

.btnsColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.btnsColumn button + button {
  margin-top: 4px;
}

@media screen and (max-width: 1044px) {
  .btnsColumn {
    align-self: flex-end;
    margin-bottom: 24px;
  }

  .btnsColumn button + button {
    margin-top: 2px;
  }
}

.error {
  color: red;
}
