.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  /* pointer-events: none; */
  z-index: 100;
}

.modal.enterDone {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modalContent {
  width: 835px;
  min-height: 331px;
  max-height: 96%;
  background-color: #fff;
  box-shadow: -3px 4px 23px rgba(0, 0, 0, 0.11);
  border-radius: 17px;
  transition: all 0.3s ease-in-out;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

@media (max-width: 890px) {
  .modalContent {
    width: 90%;
  }
}

.modal.enterDone .modalContent {
  transform: translateX(-50%) translateY(-50%);
}

.modal.exit .modalContent {
  transform: translateX(-50%) translateY(-50%);
}

.modalBody {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
}

.modalFooter {
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
