.loginBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 76px 0 40px 0;
}

div.contentBox {
  background: rgba(255, 255, 255, 0.6);
  padding: 0;
}

.loginContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 40px;
  width: 100%;
  background: url("../../images/schutz-icon.svg") no-repeat;
  background-position: calc(100% - 100px) 20px;
}

.formContainer {
  width: 821px;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resetPassword {
  margin-top: 24px;
}

.resetPassword a {
  color: var(--default-text-color);
}

@media screen and (max-width: 1044px) {
  .loginBlock {
    padding: 24px 0 50px 0;
  }

  .loginContent {
    background: none;
    padding: 32px 16px 24px;
  }

  .formContainer {
    width: 100%;
  }

  .btnContainer {
    padding-top: 8px;
  }

  .resetPassword {
    margin-top: 16px;
  }

  .resetPassword a {
    font-size: 10px;
    line-height: 13.62px;
  }
}
