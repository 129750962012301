.blockContainer {
  padding: 70px 0;
}

.block {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 38px 73px;
  position: relative;
}

@media screen and (max-width: 1044px) {
  .blockContainer {
    padding: 24px 0;
  }

  .block {
    padding: 24px 16px 32px;
  }
}

.title {
  margin-bottom: 24px;
}

@media screen and (max-width: 1044px) {
  .title {
    margin-bottom: 16px;
  }
}

.closeBtn {
  position: absolute;
  top: 36px;
  right: 36px;
}

@media screen and (max-width: 1044px) {
  .closeBtn {
    top: 16px;
    right: 16px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 25px;
}

.description {
  margin-top: 40px;
}

.categoriesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.categoriesItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.categoryItem {
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 36px;
}

.categoryItem:nth-child(odd) {
  padding: 0 20px 0 0;
}

.categoryItem:nth-child(even) {
  padding: 0 0 0 20px;
}

@media screen and (max-width: 1044px) {
  .categoryItem {
    flex: 0 0 100%;
    margin-bottom: 16px;
  }
  .categoryItem:nth-child(odd) {
    padding: 0;
  }

  .categoryItem:nth-child(even) {
    padding: 0;
  }
}

.categoryTitle,
.categoryTitle:hover {
  color: var(--color-blue-dark);
  margin: 0 0 20px 0;
  text-align: left;
  text-decoration: none;
}

.categoryTitle:hover {
  color: var(--color-blue);
}

.question {
  margin-bottom: 8px;
}

.readMore {
  color: var(--color-blue-dark);
}

.readMore:hover {
  color: var(--color-blue);
  text-decoration: underline;
}
