.blockContainer {
  padding: 70px 0;
}

.block {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 38px 73px 58px;
  position: relative;
}

@media screen and (max-width: 1044px) {
  .blockContainer {
    padding: 24px 0;
  }

  .block {
    padding: 24px 16px 32px;
  }
}

.title {
  margin-bottom: 24px;
}

@media screen and (max-width: 1044px) {
  .title {
    margin-bottom: 16px;
  }
}

.closeBtn {
  position: absolute;
  top: 36px;
  right: 36px;
}

@media screen and (max-width: 1044px) {
  .closeBtn {
    top: 16px;
    right: 16px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 25px;
}

.description {
  margin-top: 40px;
}

.categoriesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.categoryTitle {
  color: var(--color-blue-dark);
  text-align: left;
  text-decoration: none;
}

.faqArticleAnswerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
