.blockContainer {
  padding: 70px 0 83px;
}

.block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding-bottom: 38px;
}

.title {
  margin-bottom: 56px;
}

.title b {
  font-weight: 700;
}

.usernameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  width: 100%;
}

.usernameContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.usernameTitle {
  font-weight: 600;
}

.username {
  display: block;
  font-family: var(--font-family);
  font-size: var(--default-font-size-v2);
  line-height: var(--default-line-height-v2);
  color: var(--color-blue-dark);
  background: radial-gradient(
    97.01% 103.71% at 0% 3.4%,
    rgba(255, 255, 255, 0.56) 0%,
    rgba(255, 255, 255, 0.08) 100%,
    rgba(255, 255, 255, 0.16) 100%
  );
  border: 1px solid #e9e9ea;
  box-shadow: 0px 4px 10px rgba(39, 45, 84, 0.1);
  backdrop-filter: blur(12px);
  border-radius: 40px;
  padding: 20px 54px 15px 88px;
  width: auto;
  height: 73px;
  margin-top: 19px;
}

.leftIconContainer {
  position: absolute;
  width: 38px;
  height: 38px;
  top: 18px;
}

.leftIconContainer {
  left: 26px;
}

.leftIcon {
  color: var(--color-yellow);
  transition: var(--transition);
  width: 100%;
  height: auto;
}

.ulBlock {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ulBlock li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.ulBlock li + li {
  margin-top: 38px;
}

.liContent {
  flex: 1;
  padding-left: 43px;
}

.ulBlock .liTitle {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: 600;
  margin: 0;
}

.liIconContainer {
  display: flex;
  align-items: flex-start;
}

.btnContainer {
  display: flex;
  justify-content: center;
  margin-top: 56px;
  width: 100%;
}

@media (max-width: 1044px) {
  .blockContainer {
    padding: 48px 0 48px;
  }

  .block {
    padding-bottom: 0;
  }

  .title {
    margin-bottom: 48px;
  }

  .ulBlock li + li {
    margin-top: 24px;
  }

  .liContent {
    padding-left: 14px;
  }

  .btnContainer {
    margin-top: 48px;
  }
}
