input.formControl:-webkit-autofill,
input.formControl:-webkit-autofill:hover,
input.formControl:-internal-autofill-selected {
  -webkit-text-fill-color: var(--color-blue-dark);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
  box-shadow: 0 0 0px 1000px var(--color-white) inset;
  border: 1px solid #e9e9ea;
}

input.formControl:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-blue-dark);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
  box-shadow: 0 0 0px 1000px var(--color-white) inset;
  background: var(--color-white);
  border: 1px solid var(--color-blue-dark);
}

.inputContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 500px;
}

@media (max-width: 1280px) {
  .inputContainer {
    max-width: 410px;
  }
}

@media (max-width: 1044px) {
  .inputContainer {
    max-width: 100%;
  }
}

.formControl {
  font-family: var(--font-family);
  font-size: var(--default-font-size-v2);
  line-height: var(--default-line-height-v2);
  color: var(--color-blue-dark);
  background: radial-gradient(
    97.01% 103.71% at 0% 3.4%,
    rgba(255, 255, 255, 0.56) 0%,
    rgba(255, 255, 255, 0.08) 100%,
    rgba(255, 255, 255, 0.16) 100%
  );
  border: 1px solid #e9e9ea;
  box-shadow: 0px 4px 10px rgba(39, 45, 84, 0.1);
  backdrop-filter: blur(12px);
  border-radius: 40px;
  padding: 17px 25px;
  width: 100%;
  height: 73px;
  outline: none;
  transition: var(--transition);
}

.formControl:focus {
  background: var(--color-white);
  border: 1px solid var(--color-blue-dark);
  color: var(--color-blue-dark);
}

.formControl::placeholder {
  color: #e9e9ea;
}

.formControl:focus::placeholder {
  color: var(--color-gray-dark);
}

.formControl::placeholder-shown {
  border: 1px solid rgba(255, 255, 255, 0.3);
}

select.formControl,
select.formControl:focus {
  background: radial-gradient(
      97.01% 103.71% at 0% 3.4%,
      rgba(255, 255, 255, 0.56) 0%,
      rgba(255, 255, 255, 0.08) 100%,
      rgba(255, 255, 255, 0.16) 100%
    ),
    url("../assets/images/expand-bottom-icon.svg") no-repeat calc(100% - 26px)
      center/38px;
}

select.formControl:focus {
  background-color: var(--color-whitehite);
}

textarea.formControl {
  height: auto;
  padding: 17px 26px;
  min-height: 73px;
}

select.formControl {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.withIcon {
  padding: 17px 88px 17px 25px;
}

.rightIconContainer {
  position: absolute;
  width: 38px;
  height: 38px;
  top: 18px;
}

.rightIconContainer {
  right: 26px;
}

.rightIcon {
  color: var(--color-yellow);
  transition: var(--transition);
  width: 100%;
  height: auto;
}

.formControl:focus ~ .leftIconContainer .leftIcon,
.formControl:focus ~ .rightIconContainer .rightIcon {
  color: var(--color-blue-dark);
}

.arrowIcon {
  transform: rotate(90deg);
}

select.formControl:focus ~ .rightIconContainer .arrowIcon {
  transform: rotate(-90deg);
}

@media screen and (max-width: 1044px) {
  .formControl {
    height: 48px;
    padding: 15px 16px;
    border-radius: 24px;
  }

  select.formControl,
  select.formControl:focus {
    background: radial-gradient(
        97.01% 103.71% at 0% 3.4%,
        rgba(255, 255, 255, 0.56) 0%,
        rgba(255, 255, 255, 0.08) 100%,
        rgba(255, 255, 255, 0.16) 100%
      ),
      url("../assets/images/expand-bottom-icon.svg") no-repeat calc(100% - 16px)
        center/24px;
  }

  select.formControl:focus {
    background-color: var(--color-whitehite);
  }

  .withIcon {
    padding: 15px 56px;
  }

  .rightIconContainer {
    width: 24px;
    height: 24px;
    top: 16px;
  }

  .rightIconContainer {
    right: 16px;
    top: 13px;
  }
}

.filterContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.selectsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.selectsContainer > div {
  display: flex;
  flex: 0 0 50%;
  width: 50%;
}

.selectsContainer > div + div {
  justify-content: flex-end;
}

@media screen and (max-width: 1044px) {
  .selectsContainer {
    flex-direction: column;
  }

  .selectsContainer > div {
    flex: 0 0 100%;
    width: 100%;
  }

  .selectsContainer > div + div {
    justify-content: flex-end;
    margin-top: 24px;
  }
}

.pagesContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.pagesContainer h3 br {
  display: none;
}

.pagesContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pagesTitle {
  font-weight: 400;
  margin-bottom: 0;
  padding-right: 10px;
}

.pagesCount span {
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  cursor: pointer;
}

.pagesCount span + span {
  padding-left: 10px;
}

.pagesCount span.active {
  color: var(--color-yellow);
}

@media screen and (max-width: 1044px) {
  .pagesContainer h3 br {
    display: block;
  }

  .pagesContent {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .pagesTitle {
    padding-right: 0;
  }
}
