.paginationCotnainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.btn {
  padding: 0 8px;
  background: none;
  border: 0;
}

.a {
  display: flex;
  align-items: center;
  color: var(--color-blue-dark);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  transition: var(--transition);
}

.a:hover,
.active {
  color: var(--color-yellow);
}

.arrow {
  width: 38px;
  height: 38px;
}

.leftArrow {
  transform: rotate(-180deg);
}

@media screen and (max-width: 1044px) {
  .arrow {
    width: 24px;
    height: 24px;
  }
}
