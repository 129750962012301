.btnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textBlock {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: 600;
  margin: 0;
}

.ulBlock {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bubbleTop {
  position: absolute;
  top: -42px;
  right: -67px;
}

@media (max-width: 1750px) {
  .bubbleTop {
    right: -30px;
  }
}

@media (max-width: 1044px) {
  .bubbleTop {
    top: -24px;
    right: -16px;
  }
}

/* Block one */
.blockOneBg {
  background-image: url("../../images/home-block-bg.jpg");
  background-repeat: no-repeat;
  background-position: center -108px;
  background-size: 100%;
}

.blockOneContainer {
  width: 100%;
  padding: 68px 0 104px;
}

div.blockOne {
  padding: 40px 148px 68px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: none;
  align-items: flex-start;
}

.blockOne .itemsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.blockOne .textBlock {
  width: 55%;
}

.blockOne .btnContainer {
  margin-top: 76px;
}

@media (max-width: 1044px) {
  .blockOneBg {
    background-position: -100px 0;
    background-size: cover;
  }

  .blockOneContainer {
    padding: 50px 0 48px;
  }

  div.blockOne {
    padding: 32px 16px;
    align-items: flex-start;
  }

  div.blockOne h1 {
    padding-right: 60px;
  }

  .blockOne .itemsContainer {
    flex-direction: column;
    justify-content: flex-start;
  }

  .blockOne .ulBlock {
    order: 1;
    margin-top: 12px;
  }

  .blockOne .textBlock {
    order: 2;
    margin-top: 28px;
    width: 100%;
  }

  .blockOne .btnContainer {
    margin-top: 56px;
  }
}

/* Block two */
.blockTwoContainer {
  width: 100%;
  padding: 158px 0 0 0;
}

div.blockTwo {
  padding: 40px 148px 68px;
  align-items: flex-start;
}

.blockTwo .ulBlock li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.blockTwo .ulBlock li + li {
  margin-top: 38px;
}

.liContent {
  flex: 1;
  padding-left: 43px;
}

.blockTwo .ulBlock p {
  margin: 0;
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
}

.blockTwo .ulBlock .liTitle {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: 600;
}

.liIconContainer {
  display: flex;
  align-items: flex-start;
}

.blockTwo .btnContainer {
  margin-top: 52px;
}

@media (max-width: 1044px) {
  .blockTwoContainer {
    padding: 48px 0 0 0;
  }

  div.blockTwo {
    padding: 16px 16px 32px;
  }

  .blockTwo .ulBlock li + li {
    margin-top: 24px;
  }

  .liContent {
    padding-left: 14px;
  }

  .blockTwo .btnContainer {
    margin-top: 56px;
  }
}

/* Block three */
.blockThreeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 163px 0 0 0;
}

.blockThree {
  width: 100%;
  display: flex;
  max-width: var(--container-lg-width-fixed);
  flex-direction: column;
  justify-content: flex-start;
}

.blockThreeContainer .blockSlider {
  width: 100%;
}

@media (max-width: 1280px) {
  .blockThree {
    padding: 0 20px;
  }
}

@media (max-width: 1044px) {
  .blockThreeContainer {
    padding: 48px 0 0 0;
  }

  .blockThree {
    align-items: flex-start;
    padding: 0 20px;
  }
}

/* Block four */
.blockFourContainer {
  width: 100%;
  padding: 163px 0 0 0;
}

div.blockFour {
  padding: 38px 100px 52px 73px;
  align-items: flex-start;
}

.smallTextBlock {
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  margin: 25px 0;
}

.videoContainer {
  position: relative;
  display: inline-flex;
  width: 100%;
  max-width: 687px;
  padding-top: 35.7%;
  margin: 25px auto;
  height: auto;
  background: #ffffff;
}

.videoContainer video {
  width: 100%;
  height: auto;
}

.playIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: pointer;
}

.blockFour .btnContainer {
  margin-top: 72px;
}

@media (max-width: 1044px) {
  .blockFourContainer {
    padding: 48px 0 0 0;
  }

  div.blockFour {
    padding: 16px 16px 32px;
  }

  .videoContainer {
    width: 100%;
    height: auto;
    padding-bottom: 20.3%;
    margin: 8px auto 0;
  }

  .smallTextBlock {
    margin: 16px 0;
  }

  .blockFour .btnContainer {
    margin-top: 16px;
  }
}

/* Block five */
.blockFiveContainer {
  width: 100%;
  padding: 130px 0 120px 0;
}

div.blockFive {
  padding: 38px 148px 52px 73px;
  align-items: flex-start;
}

h2.blockFiveTitle {
  width: 100%;
  margin-bottom: 20px;
}

.withImgContainer {
  display: flex;
  width: 100%;
}

.blockFive .itemsContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex-grow: 1;
  padding: 57px 0 16px 0;
}

.blockFiveImg {
  width: 398px;
  height: 617px;
}

.blockFive .btnContainer {
  margin-top: 49px;
}

@media (max-width: 1044px) {
  .blockFiveContainer {
    padding: 48px 0;
  }

  div.blockFive {
    padding: 16px 16px 32px;
  }

  h2.blockFiveTitle {
    padding-right: 60px;
  }

  .blockFive .itemsContainer {
    flex: 1 1 50%;
    padding: 0;
  }

  .blockFive .ulBlock {
    margin-top: 20px;
  }

  .imgContainer {
    display: flex;
    flex: 1 1 50%;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .blockFiveImg {
    width: 100%;
    max-width: 110px;
    height: auto;
  }

  .blockFive .btnContainer {
    margin-top: 56px;
  }
}
