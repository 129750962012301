.cashbackItemContainer {
  width: 353px;
  height: 365px;
  margin: 24px 13px;
}

@media (max-width: 1280px) {
  .cashbackItemContainer {
    width: 274px;
    height: 347px;
  }
}

.cashbackItem {
  padding: 40px 28px 18px;
}

.cashbackItemA {
  width: 100%;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 12px 45px;
  width: calc(100% - 24px);
  height: 131px;
  border-radius: 8px;
  background: var(--color-white);
}

.imgContainer img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: 60%;
}

.line {
  width: 100%;
  height: 6px;
  border-radius: 2px;
  background: var(--color-yellow);
}

.cashbackTitle {
  font-weight: 700;
  text-align: center;
  margin: 25px 0 0 0;
}

.description {
  padding: 7px 12px 0;
  text-align: center;
}

@media screen and (max-width: 1044px) {
  .cashbackItemContainer {
    width: 100%;
    min-height: 226px;
    height: auto;
    margin: 0 0 24px;
  }

  .cashbackItem {
    padding: 29px 23px 9px;
  }

  .imgContainer {
    height: 101px;
    padding: 0 13px;
    margin-bottom: 23px;
  }

  .imgContainer img {
    width: auto;
  }

  .line {
    height: 2px;
  }

  .cashbackTitle {
    margin-top: 9px;
  }
}
