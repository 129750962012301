.modalBody {
  padding: 38px 108px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.content {
  padding-top: 38px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.p {
  margin: 0;
}

.bottom {
  margin-top: 38px;
  display: flex;
  align-items: center;
}

.bottomP {
  margin: 0;
  padding-right: 38px;
}

.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.topImg {
  max-width: 100%;
  max-height: 150px;
  width: auto;
  height: auto;
}

.img {
  max-width: 150px;
  max-height: 60px;
  width: auto;
  height: auto;
}

@media screen and (max-width: 1044px) {
  .modalBody {
    padding: 16px;
  }

  .content {
    padding-top: 16px;
  }

  .topImg {
    max-height: 80px;
  }

  .img {
    max-width: 100px;
    max-height: 45px;
  }

  .bottom {
    margin-top: 16px;
  }

  .bottomP {
    padding-right: 16px;
  }
}
