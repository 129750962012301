input.formControl:-webkit-autofill,
input.formControl:-webkit-autofill:hover,
input.formControl:-internal-autofill-selected {
  -webkit-text-fill-color: var(--color-blue-dark);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
  box-shadow: 0 0 0px 1000px var(--color-white) inset;
  border: 1px solid #e9e9ea;
}

input.formControl:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-blue-dark);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
  box-shadow: 0 0 0px 1000px var(--color-white) inset;
  background: var(--color-white);
  border: 1px solid var(--color-blue-dark);
}

.formGroup {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  margin-bottom: 44px;
}

.inputContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.formControl {
  font-family: var(--font-family);
  font-size: var(--default-font-size-v2);
  line-height: var(--default-line-height-v2);
  color: var(--color-blue-dark);
  background: radial-gradient(
    97.01% 103.71% at 0% 3.4%,
    rgba(255, 255, 255, 0.56) 0%,
    rgba(255, 255, 255, 0.08) 100%,
    rgba(255, 255, 255, 0.16) 100%
  );
  border: 1px solid #e9e9ea;
  box-shadow: 0px 4px 10px rgba(39, 45, 84, 0.1);
  backdrop-filter: blur(12px);
  border-radius: 40px;
  padding: 17px 25px;
  width: 100%;
  height: 73px;
  outline: none;
  transition: var(--transition);
}

.formControl:focus {
  background: var(--color-white);
  border: 1px solid var(--color-blue-dark);
  color: var(--color-blue-dark);
}

.formControl::placeholder {
  color: #e9e9ea;
}

.formControl:focus::placeholder {
  color: var(--color-gray-dark);
}

.formControl::placeholder-shown {
  border: 1px solid rgba(255, 255, 255, 0.3);
}

textarea.formControl {
  height: auto;
  padding: 17px 26px;
  min-height: 73px;
}

select.formControl {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.formControl,
select.formControl:focus {
  background: radial-gradient(
      97.01% 103.71% at 0% 3.4%,
      rgba(255, 255, 255, 0.56) 0%,
      rgba(255, 255, 255, 0.08) 100%,
      rgba(255, 255, 255, 0.16) 100%
    ),
    url("../assets/images/expand-bottom-icon.svg") no-repeat calc(100% - 26px)
      center/38px;
}

select.formControl:focus {
  background-color: var(--color-whitehite);
}

.withLeftIcon {
  padding-left: 88px;
}

.withRightIcon {
  padding-right: 88px;
}

.leftIconContainer,
.rightIconContainer {
  position: absolute;
  width: 38px;
  height: 38px;
  top: 18px;
}

.leftIconContainer {
  left: 26px;
}

.rightIconContainer {
  right: 26px;
}

.rightIconClick {
  cursor: pointer;
}

.leftIcon,
.rightIcon {
  color: var(--color-yellow);
  transition: var(--transition);
  width: 100%;
  height: auto;
}

.rightIconClick:hover .rightIcon {
  color: var(--color-blue-dark);
}

.formControl:focus ~ .leftIconContainer .leftIcon,
.formControl:focus ~ .rightIconContainer:not(.rightIconClick) .rightIcon {
  color: var(--color-blue);
}

.formControlError,
.formControlError:focus {
  color: red;
  border-color: red;
}

.fieldError {
  color: red;
  font-size: 16px;
  padding-left: 25px;
}

.inputLabel {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: 600;
  margin-bottom: 19px;
}

@media screen and (max-width: 1044px) {
  .formGroup {
    margin-bottom: 16px;
  }

  .formControl {
    height: 48px;
    padding: 15px 16px;
    border-radius: 24px;
  }

  select.formControl,
  select.formControl:focus {
    background: radial-gradient(
        97.01% 103.71% at 0% 3.4%,
        rgba(255, 255, 255, 0.56) 0%,
        rgba(255, 255, 255, 0.08) 100%,
        rgba(255, 255, 255, 0.16) 100%
      ),
      url("../assets/images/expand-bottom-icon.svg") no-repeat calc(100% - 16px)
        center/24px;
  }

  select.formControl:focus {
    background-color: var(--color-whitehite);
  }

  .withLeftIcon {
    padding-left: 56px;
  }

  .withRightIcon {
    padding-right: 56px;
  }

  .fieldError {
    font-size: 10px;
    padding-left: 16px;
  }

  .inputLabel {
    margin-bottom: 16px;
  }

  .leftIconContainer,
  .rightIconContainer {
    width: 24px;
    height: 24px;
    top: 12px;
  }

  .leftIconContainer {
    left: 16px;
  }

  .rightIconContainer {
    right: 16px;
  }
}
