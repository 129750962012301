.h2 {
  margin: 25px 0 35px;
}

.h4 {
  font-weight: 600;
  margin: 15px 0;
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
}

.textRight {
  text-align: right;
}

.block {
  width: 100%;
  padding: 50px 0;
}

@media screen and (max-width: 1044px) {
  .block {
    padding: 20px 0;
  }
}
