.bankLogo img {
  max-width: 280px;
  max-height: 60px;
  height: auto;
}

@media (max-width: 1044px) {
  .bankLogo img {
    max-width: 150px;
    max-height: 30px;
  }
}
