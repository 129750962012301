button.longBtn,
a.longBtn {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  border-radius: 48px;
  width: 100%;
  max-width: 957px;
  height: 73px;
  padding: 17px 44px 17px 77px;
}

@media (max-width: 1044px) {
  button.longBtn,
  a.longBtn {
    width: 100%;
    min-height: 48px;
    height: auto;
    padding: 11px 38px 12px;
  }
}
