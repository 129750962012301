.btnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ulBlock {
  margin: 0;
  padding: 0;
  list-style: none;
}

.textBlock {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: 600;
  margin: 0;
}

.smallTextBlock {
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  margin: 32px 0 0 0;
}

.twoDivs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.half {
  flex: 1;
}

@media (max-width: 1044px) {
  .half {
    width: 100%;
  }
  .half + .half {
    display: none;
  }
}

/* Block one */
.blockOneContainer {
  width: 100%;
  padding: 70px 0 0 0;
}

div.blockOne {
  padding: 44px 148px 52px;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.bankLogoContainer {
  margin-bottom: 38px;
}

.blockOne .textBlock {
  margin-top: 19px;
  width: calc(100% - 75px);
}

.blockOne .textBlock + .textBlock {
  margin-top: 25px;
}

.blockOne .btnContainer {
  margin-top: 51px;
}

.schutzIconContainer {
  position: absolute;
  top: 75px;
  right: 65px;
  width: 164px;
  height: 168px;
}

.schutzIcon {
  width: 100%;
  height: auto;
}

@media (max-width: 1044px) {
  .blockOneContainer {
    padding: 24px 0 0 0;
  }

  .bankLogoContainer {
    margin-bottom: 16px;
  }

  div.blockOne {
    align-items: flex-start;
    padding: 32px 16px;
  }

  .blockOne .textBlock {
    margin-top: 0;
    width: 100%;
  }

  .blockOne .textBlock + .textBlock {
    margin-top: 16px;
  }

  .blockOne .btnContainer {
    margin-top: 32px;
  }

  .schutzIconContainer {
    top: 50px;
    right: 0;
    width: 76px;
    height: 78px;
  }
}

/* Block two */
.blockTwoContainer {
  width: 100%;
  padding: 163px 0 0;
}

div.blockTwo {
  padding: 44px 80px 52px;
  align-items: flex-start;
}

.newStartText {
  color: var(--color-blue);
}

.calcContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0 35px;
}

@media (max-width: 1044px) {
  .blockTwoContainer {
    padding: 48px 0 0;
  }

  div.blockTwo {
    padding: 16px 16px 32px;
  }
}

/* Block three */
.blockThreeContainer {
  width: 100%;
  padding: 0 0 0 0;
}

div.blockThree {
  padding: 19px 53px 12px 19px;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.priceContainer {
  align-self: flex-end;
  color: var(--color-yellow);
  background: var(--color-blue-dark);
  border-radius: 48px;
  padding: 6px 20px;
  margin: 0;
}

.priceRequiredText {
  align-self: flex-end;
  margin-top: 12px;
}

.blockThreeContainer .btnContainer {
  display: flex;
  flex-direction: column;
  margin-top: 73px;
}

.blockThreeContainer .btnContainer button + a,
.blockThreeContainer .btnContainer a + a {
  margin-top: 25px;
}

@media (min-width: 1044px) {
  .blockThreeTitle {
    margin: 0;
  }
}

@media (max-width: 1044px) {
  .blockThreeContainer {
    padding: 0 0 0 0;
  }

  div.blockThree {
    align-items: flex-start;
    padding: 16px;
  }

  .priceContainer {
    padding: 4px 12px;
  }
  .priceRequiredText {
    font-size: 10px;
    line-height: 13px;
    margin-top: 6px;
  }

  .blockThreeContainer .btnContainer {
    margin-top: 32px;
  }

  .blockThreeContainer .btnContainer button + a,
  .blockThreeContainer .btnContainer a + a {
    margin-top: 12px;
  }
}

/* Block four */
.blockFourContainer {
  width: 100%;
  padding: 163px 0;
}

div.blockFour {
  padding: 44px 80px 52px;
  align-items: flex-start;
}

.li + .li {
  margin-top: 30px;
}

.videoContainer {
  position: relative;
  display: inline-flex;
  width: 100%;
  max-width: 687px;
  padding-top: 35.7%;
  margin: 51px auto 0;
  height: auto;
  background: #ffffff;
}

.videoContainer video {
  width: 100%;
  height: auto;
}

.playIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: pointer;
}

.blockFour .btnContainer {
  margin-top: 57px;
}

@media (max-width: 1044px) {
  .blockFourContainer {
    padding: 48px 0;
  }

  div.blockFour {
    padding: 16px 16px 32px;
  }

  .li + .li {
    margin-top: 24px;
  }

  .videoContainer {
    width: 100%;
    height: auto;
    padding-bottom: 20.3%;
    margin: 24px auto 0;
  }

  .blockFour .btnContainer {
    margin-top: 32px;
  }
}

.modalFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 35px;
}

@media (max-width: 540px) {
  .actionBtn {
    width: 100%;
    min-height: 48px;
    height: auto;
    padding: 11px 38px 12px;
  }
}
