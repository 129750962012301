.blockContainer {
  padding: 70px 0;
}

.block {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 38px 73px;
  position: relative;
}

@media screen and (max-width: 1044px) {
  .blockContainer {
    padding: 24px 0;
  }

  .block {
    padding: 24px 16px 32px;
  }
}

.title {
  margin-bottom: 18px;
}

.requiredInfo {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: 600;
  margin-bottom: 38px;
}

@media screen and (max-width: 1044px) {
  .title {
    margin-bottom: 16px;
  }

  .requiredInfo {
    margin-bottom: 32px;
  }
}

.closeBtn {
  position: absolute;
  top: 36px;
  right: 36px;
}

@media screen and (max-width: 1044px) {
  .closeBtn {
    top: 16px;
    right: 16px;
  }
}

.profileBlock {
  padding: 30px 0 45px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formGroupInline {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row;
  max-width: 825px;
  width: 100%;
}

.label {
  max-width: 256px;
  width: 100%;
  margin-bottom: 0;
}

.fixedLabel {
  width: 256px;
}

.input {
  max-width: 500px;
  width: 100%;
}

.fixedInput {
  width: 336px;
}

@media screen and (max-width: 1044px) {
  .formGroupInline {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .label {
    max-width: none;
    margin-bottom: 16px;
  }

  .fixedLabel {
    width: 100%;
  }

  .input {
    max-width: none;
    width: 100%;
  }

  .fixedInput {
    width: 100%;
  }
}

.formGroupInline .smallInput {
  width: 150px;
  flex: 0 0 150px;
}

.textCenter input {
  text-align: center;
}

.formGroupInlineLeftMargin {
  justify-content: flex-end;
  margin-left: 14px;
}

@media screen and (max-width: 1044px) {
  .formGroupInline .smallInput {
    width: 100%;
    flex: 0 0 100%;
  }

  .textCenter input {
    text-align: left;
  }

  .formGroupInlineLeftMargin {
    justify-content: flex-start;
    margin-left: 0;
  }
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 56px;
  margin-bottom: 24px;
}

@media screen and (max-width: 1044px) {
  .btnContainer {
    margin-top: 32px;
    margin-bottom: 0;
  }
}

.checkboxesConatiner {
  margin-top: 40px;
}

.twowayTitles {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 60px;
}

@media screen and (max-width: 1044px) {
  .twowayTitles {
    margin-bottom: 16px;
  }
}

.twowayDefaultItem {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 130px;
  text-align: center;
  margin-bottom: 0;
}

.twowayDefaultItemCheckbox {
  margin-bottom: 0;
}

.twowayActiveItem {
  width: 70px;
  margin: 0 0 0 27px;
}

@media screen and (max-width: 1044px) {
  .twowayDefaultItem {
    width: 75px;
  }

  .twowayActiveItem {
    width: 40px;
    margin: 0 0 0 8px;
  }
}

.authOneLine {
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 1044px) {
  .authOneLine {
    align-items: flex-start;
  }
}

.checkboxAuthTypeConatiner {
  display: flex;
  justify-content: space-between;
}

.twowayDefaultItems {
  display: flex;
}

.defaultLabel {
  font-size: 10px;
  line-height: 1;
  padding-left: 5px;
}

.passwordHintContainer {
  margin: 0;
  font-size: var(--default-font-size-v2);
  line-height: var(--default-line-height-v2);
}

.passwordHintContainer {
  position: relative;
}

.passwordHint {
  display: flex;
  align-items: center;
}

.titleIconWrapper {
  display: flex;
  align-items: center;
}

.infoIcon {
  color: var(--color-blue-dark);
  width: 38px;
  height: 38px;
  cursor: pointer;
  margin-left: 10px;
  outline: none;
}

.infoIconGoogle {
  color: var(--color-blue-dark);
  height: 100%;
  width: 34px;
  height: 34px;
  cursor: pointer;
  margin-left: 5px;
  outline: none;
  flex-shrink: 0;
}

.authTooltipContent {
  max-width: 600px;
}

.authTooltipH3 {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 10px;
}

.authTooltipP {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.authTooltipUl {
  padding-left: 20px;
}

.authTooltipUl li {
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 1044px) {
  .passwordHint {
    padding-top: 8px;
  }

  .infoIcon,
  .infoIconGoogle {
    width: 24px;
    height: 24px;
  }
}

.twowayAuthModal {
  max-width: 900px;
}
