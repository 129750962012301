.modalConfirmContent {
  min-width: 360px;
  max-width: 820px;
  min-height: 230px;
  top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 1040px) {
  .modalConfirmContent {
    max-width: 600px;
  }
}

.description {
  width: 100%;
}
