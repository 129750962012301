.officeContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: var(--container-admin-page-width);
  padding-bottom: 30px;
}
.table {
  border-collapse: collapse;
}
.greeting {
  font-weight: 500;

  font-size: 22px;
  margin: 70px 0 55px;
  font-family: var(--font-family);
}

.cell {
  font-size: 16px;
  padding: 10px;
  border: 1px solid var(--input-border-color);
}

.cellTitle {
  font-weight: 500;
}

.tableWrapper {
  padding-top: 50px;
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
}
