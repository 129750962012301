.h2 {
  font-weight: 600;
  margin: 35px 0 15px;
}

@media screen and (max-width: 1044px) {
  .h2 {
    margin: 20px 0 10px;
  }
}

.h4 {
  font-weight: 600;
  margin: 15px 0;
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
}

.textRight {
  text-align: right;
}

.block {
  width: 100%;
  padding: 50px 0;
}

@media screen and (max-width: 1044px) {
  .block {
    padding: 30px 0 20px;
  }
}

.halfs {
  display: flex;
  flex-direction: column;
}

.halfsItem {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.halfsItem p {
  margin: 0;
}

.halfLeft {
  flex: 1;
}

.halfRight {
  flex: 2;
}

@media screen and (max-width: 1044px) {
  .halfsItem {
    flex-direction: column;
  }

  .halfLeft {
    margin-top: 16px;
  }

  .halfsItem:first-child .halfLeft {
    margin-top: 0;
  }

  .halfRight {
    flex: 1;
  }
}
