.link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-white);
  cursor: pointer;
  text-decoration: none;
  transition: var(--transition);
}

.link:hover,
.active {
  color: var(--color-yellow);
  text-decoration: none;
}

.iconContainer {
  display: flex;
  align-self: center;
  padding-right: 8px;
}

.icon {
  color: var(--color-white);
  width: 25px;
  height: 25px;
}

.link:hover .icon,
.active .icon {
  color: var(--color-yellow);
}
