.responsiveTable {
  width: 100%;
  min-height: 580px;
  max-height: 580px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  border: 0;
  border-spacing: 0;
}
