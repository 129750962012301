.link {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: 400;
  color: var(--color-blue-dark);
  min-height: var(--h3-line-height);
  border: 0;
  min-width: 0;
  width: auto;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: var(--transition);
}

.link:hover {
  text-decoration: underline;
}
