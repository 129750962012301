.h2 {
  font-family: var(--secondary-font-family);
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  font-weight: 900;
  margin: 0 0 38px 0;
}

.child {
  width: 100%;
}

.line {
  width: 44px;
  border-bottom: 6px solid var(--color-yellow);
  margin-top: 25px;
}

@media (max-width: 1044px) {
  .h2 {
    margin: 0 0 24px 0;
  }

  .line {
    width: 32px;
    border-bottom-width: 4px;
    margin-top: 16px;
  }
}
