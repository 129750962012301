.confimationModalContent {
  max-width: 560px;
  min-height: 400px;
  font-family: var(--font-family);
}

.modalContentWithForm {
  max-width: 800px;
}

@media (max-width: 1044px) {
  .confimationModalContent {
    max-width: 400px;
    min-height: 340px;
  }
}

.confimationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 380px;
  position: relative;
}

@media (max-width: 1044px) {
  .confimationContainer {
    min-height: 340px;
  }
}

.confimationContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px 20px;
}

.confimationContent .pinH3 {
  margin-bottom: 10px;
}

.confimationContent .pinP {
  font-size: 16px;
  margin: 0 0 30px;
  text-align: center;
  max-width: 80%;
}

.employerFormContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0 10px 0;
}

.employerFormContainer .pinH3 {
  margin-bottom: 25px;
}

.confirmationInput {
  width: 200px;
}

@media (max-width: 1044px) {
  .confirmationInput {
    width: 200px;
  }

  .confimationContent .pinP {
    font-size: 14px;
    margin: 0 0 20px;
    max-width: 100%;
  }
}

.confirmationBtn {
  margin: 0 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1044px) {
  .confirmationBtn {
    margin: 0 0 10px;
  }
}

.waitingLoaderContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(233, 233, 234, 0.2);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
}
