input.searchBar:-webkit-autofill,
input.searchBar:-webkit-autofill:hover,
input.searchBar:-internal-autofill-selected {
  -webkit-text-fill-color: var(--color-blue-dark);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
  box-shadow: 0 0 0px 1000px var(--color-white) inset;
  border: 1px solid #e9e9ea;
}

input.searchBar:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-blue-dark);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
  box-shadow: 0 0 0px 1000px var(--color-white) inset;
  background: var(--color-white);
  border: 1px solid var(--color-blue-dark);
}

.searchBarContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.searchBar {
  font-family: var(--font-family);
  font-size: var(--default-font-size-v2);
  line-height: var(--default-line-height-v2);
  color: var(--color-blue-dark);
  background: radial-gradient(
    97.01% 103.71% at 0% 3.4%,
    rgba(255, 255, 255, 0.56) 0%,
    rgba(255, 255, 255, 0.08) 100%,
    rgba(255, 255, 255, 0.16) 100%
  );
  border: 1px solid #e9e9ea;
  box-shadow: 0px 4px 10px rgba(39, 45, 84, 0.1);
  backdrop-filter: blur(12px);
  border-radius: 40px;
  padding: 17px 25px;
  width: 100%;
  height: 73px;
  outline: none;
  transition: var(--transition);
}

.searchBar:focus {
  background: var(--color-white);
  border: 1px solid var(--color-blue-dark);
  color: var(--color-blue-dark);
}

.searchBar::placeholder {
  color: #e9e9ea;
}

.searchBar:focus::placeholder {
  color: var(--color-gray-dark);
}

.searchBar::placeholder-shown {
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.withIcon {
  padding: 17px 88px;
}

.rightIconContainer {
  position: absolute;
  width: 38px;
  height: 38px;
  top: 18px;
}

.rightIconContainer {
  right: 26px;
}

.rightIcon {
  color: var(--color-yellow);
  transition: var(--transition);
  width: 100%;
  height: auto;
}

.searchBar:focus ~ .rightIconContainer .rightIcon {
  color: var(--color-blue-dark);
}

@media screen and (max-width: 1044px) {
  .searchBar {
    height: 48px;
    padding: 15px 16px;
    border-radius: 24px;
  }

  .withIcon {
    padding: 15px 56px;
  }

  .rightIconContainer {
    width: 24px;
    height: 24px;
    top: 16px;
  }

  .rightIconContainer {
    right: 16px;
    top: 13px;
  }
}

.auto {
  display: flex;
  flex-direction: column;
  width: 820px;
  align-items: center;
  z-index: 1;
  position: relative;
}

@media screen and (max-width: 1044px) {
  .auto {
    width: 100%;
  }
}

.cancelBtn {
  color: var(--color-blue-dark);
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
}

.cancelBtn .rightIcon {
  color: var(--color-blue-dark);
  transition: var(--transition);
}

.cancelBtn:hover,
.cancelBtn:hover .rightIcon {
  color: var(--color-blue);
}

.search {
  display: flex;
  flex-direction: row;
}

.searchResultsWrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 15px;
  width: 100%;
  max-height: 400px;
  background-color: var(--color-white);
  top: 100%;
  position: absolute;
  border-radius: 16px;
  margin-top: 3px;
  box-shadow: 0px 10px 15px rgb(0 0 0 / 20%);
}

.searchResults {
  display: flex;
  flex-direction: column;
  border: none;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
}

.searchPreview {
  transition: 1s;
  display: flex;
  align-items: baseline;
  width: 100%;
  flex-direction: column;
  cursor: pointer;
  padding: 15px 10px;
}

@media screen and (max-width: 1044px) {
  .searchPreview {
    padding: 8px 6px;
  }
}

.category {
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  color: var(--color-yellow);
  padding: 0;
}

.question {
  font-weight: 600;
  color: var(--default-text-colorcolor);
  padding: 5px 0;
}

.answer,
.answer:hover {
  color: var(--default-text-color);
}
