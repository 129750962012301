.modalContainer {
  transition: all 300ms ease-in-out;
  /* transform: translateY(-200%); */
  overflow-y: auto;
}

.modalCotainer.enterDone {
  transform: translateY(0);
}

.modalCotainer.exit {
  opacity: 0;
  transform: translateY(-200%);
}

.block {
  background: var(--color-white);
  padding: 0;
}
