.td {
  padding: 10px;
  color: #5c5c5c;
  border-bottom: 1px solid #f7f7f7;
  background: #fff;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.stickyRightContainer {
  position: sticky;
  right: 0;
}

.stickyLeftContainer {
  position: sticky;
  left: 0;
  z-index: 1;
}

.td .content {
  display: flex;
  align-items: center;
  height: 36px;
  min-width: 36px;
}

.td:not(.td:first-child, .td:last-child) .content {
  min-width: 160px;
}

.left .content {
  justify-content: flex-start;
}

.center .content {
  justify-content: center;
}

.right .content {
  justify-content: flex-end;
}
