.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(233, 233, 234, 0.7);
  backdrop-filter: blur(20px);
  transition: var(--transition);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  overflow: auto;
}

.modal.show {
  opacity: 1;
  pointer-events: visible;
  visibility: visible;
}
