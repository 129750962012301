.bubble {
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: 400;
  color: var(--color-blue-dark);
  text-align: center;
  width: 156px;
  height: 156px;
  background: var(--color-yellow);
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  padding: 30px 10px 0;
}

.arrowIcon {
  fill: var(--color-blue-dark);
  transform: rotate(90deg);
  width: 38px;
  height: 38px;
}

@media (max-width: 1280px) {
  .bubble {
    font-size: 10px;
    line-height: 13.62px;
    width: 86px;
    height: 86px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 16px 4px 0;
  }

  .arrowIcon {
    width: 24px;
    height: 24px;
  }
}
